import { createBrowserHistory } from 'history';
import { authAction } from "../modules";

export const history = createBrowserHistory();

export const handleAuthorization = ({ dispatch }) => next => action => {
  if (action.payload && action.payload.status === 401) {
    dispatch(authAction.logout());
    history.push("/login");
  } else if (action.payload && action.payload.status === 403) {
    dispatch(authAction.logout());
    history.push("/login");
  } else {
    next(action);
  }
};
