import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesAction } from "../../../redux/modules";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Girls from "../../../assets/images/games/lucky-wheel/girls.png";

const GameOver = ({ activeGame, setIsFinish }) => {
  const dispatch = useDispatch();
  const findMate = useSelector((store) => store.games.findMate);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    if( activeGame ) {

      console.log("activeGame: ", activeGame);

      dispatch(gamesAction.setFindMate(activeGame)).then(res => {
        setResponseMessage(res.data.responseText);
        setIsFinish(true);
      });
    }

    return () => {};
  }, [dispatch, activeGame]);

  return (  
    <Row className="justify-content-md-center">
      <Col xs={12} className="text-center find-mate-win active">
        <h1>Tebrikler Kazandınız!</h1>
        {responseMessage && <p className="!m-0">{responseMessage}</p> }
        
        <div className="wrapper-reward active">
          <img src={Girls} alt="" />
          <h4>{findMate?.score}</h4>
        </div>
      </Col>
    </Row>
  );
};

export default GameOver;
