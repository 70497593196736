export const GET_SHOP_CATEGORIES_PENDING = "GET_SHOP_CATEGORIES_PENDING";
export const GET_SHOP_CATEGORIES_FULFILLED = "GET_SHOP_CATEGORIES_FULFILLED";
export const GET_SHOP_CATEGORIES_REJECTED = "GET_SHOP_CATEGORIES_REJECTED";

export const ADD_TO_CART_PENDING = "ADD_TO_CART_PENDING";
export const ADD_TO_CART_FULFILLED = "ADD_TO_CART_FULFILLED";
export const ADD_TO_CART_REJECTED = "ADD_TO_CART_REJECTED";

export const GET_CART_PENDING = "GET_CART_PENDING";
export const GET_CART_FULFILLED = "GET_CART_FULFILLED";
export const GET_CART_REJECTED = "GET_CART_REJECTED";
