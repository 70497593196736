import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { aboutAction } from "../../../redux/modules";

const UserportalFooter = () => {
  const dispatch = useDispatch();
  const footerAboutUs = useSelector((store) => store.about.footerAboutUs);
  // const footerAboutUsTyped = useSelector((store) => store.about.footerAboutUsTyped);

  useEffect(() => {
    dispatch(aboutAction.getFooterAboutUs());
  
    return () => {}
  }, [dispatch]);

  const menuItems = [
    { id: 1, title: "AKADEMİ", url: "/academy" },
    { id: 2, title: "FORUM", url: "/forum" },
    { id: 3, title: "OYUNLAR", url: "/games" },
    { id: 4, title: "MARKALARIMIZ", url: "/brands" },
    { id: 5, title: "DÜKKAN", url: "/shop" },
    { id: 6, title: "TV", url: "/tv" },
    { id: 7, title: "DERGİ", url: "/magazine" },
    { id: 8, title: "HAKKIMIZDA", url: "/about-us" },
  ];

  return (
    <>
      <footer className="bg-gray-darkest">
        <div className="container">
          <div className="py-sm-8 py-5">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <a
                  href="tel:08505325787"
                  className="d-block text-white text-decoration-none mb-lg-5 mb-4"
                >
                  <i className="fas fa-phone-alt me-3" />
                  0850 532 5787
                </a>
                <a 
                href="mailto:info@jarvis.com"
                className="d-block text-white text-decoration-none mb-lg-5 mb-4"
                >info@jarvis.com</a>
                <div className="h4 mb-0 opacity-50">
                  <a
                    href="#"
                    target="_blank"
                    className="text-white text-decoration-none me-sm-5 me-3"
                  >
                    <i className="fab fa-facebook" />
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="text-white text-decoration-none me-sm-5 me-3"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="text-white text-decoration-none me-sm-5 me-3"
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="text-white text-decoration-none"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
              <div style={{marginLeft: 'auto'}} className="col-lg-2 col-md-3 col-6">
                <ul className="list-unstyled small opacity-50 d-grid gap-2">
                  {menuItems.map((item) => (
                    <Link
                      key={item.id}
                      className="text-white text-decoration-none"
                      to={{
                        pathname: item.url,
                      }}
                    >
                      <li>{item.title}</li>
                    </Link>
                  ))}
                </ul>
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                {footerAboutUs?.length > 0 && footerAboutUs.map((item) => (
                  <Link
                    key={item.id}
                    className="d-block h6 mb-3 text-white text-decoration-none fw-normal"
                    to={{
                      pathname: `/static/${item.slug}`,
                    }}
                  >
                    <span>{item.title}</span>
                  </Link>
                ))}

                  <Link
                    className="d-block h6 mb-3 text-white text-decoration-none fw-normal"
                    to={{
                      pathname: `profile/messages`,
                    }}
                  >
                    <span>Bize Ulaşın</span>
                  </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center border-top text-white fw-light small opacity-25 py-4">
          <div className="container">
            <p className="my-2">
              Copyright © 2024 Jarvis. Tüm Hakları Saklıdır.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default UserportalFooter;
