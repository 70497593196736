import * as brandTypes from "./brand.type";

const initialState = {
  brandList: [],
  brandStyleList: [],
  brandCategoryList: [],
  brandMagazine: [],
  brandTopic: [],
  favouriteBrandStyle: null,
  brandProductFavouriteList: null,
  error: null,
  isLoading: false
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${brandTypes.GET_BRAND_LIST_PENDING}`:
    case `${brandTypes.GET_BRAND_PRODUCT_FAVOURITE_LIST_PENDING}`:
    case `${brandTypes.GET_BRAND_STYLE_LIST_PENDING}`:
    case `${brandTypes.GET_BRAND_CATEGORY_LIST_PENDING}`:
    case `${brandTypes.GET_BRAND_MAGAZINE_PENDING}`:
    case `${brandTypes.GET_BRAND_TOPIC_PENDING}`:
    case `${brandTypes.GET_BRAND_SUBCATEGORY_STYLE_LIST_PENDING}`:
    case `${brandTypes.SET_FAVOURITE_BRAND_STYLE_PENDING}`:
    case `${brandTypes.DELETE_FAVOURITE_BRAND_STYLE_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${brandTypes.GET_BRAND_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        brandList: payload.data
      };
    case `${brandTypes.GET_BRAND_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        brandList: []
      };

    case `${brandTypes.GET_BRAND_PRODUCT_FAVOURITE_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        brandProductFavouriteList: payload.data
      };
    case `${brandTypes.GET_BRAND_PRODUCT_FAVOURITE_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        brandProductFavouriteList: null,
      };

    case `${brandTypes.GET_BRAND_STYLE_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        brandStyleList: payload.data
      };
    case `${brandTypes.GET_BRAND_STYLE_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        brandStyleList: []
      };

    case `${brandTypes.GET_BRAND_CATEGORY_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        brandCategoryList: payload.data
      };
    case `${brandTypes.GET_BRAND_CATEGORY_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        brandCategoryList: []
      };

    case `${brandTypes.GET_BRAND_MAGAZINE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        brandMagazine: payload.data
      };
    case `${brandTypes.GET_BRAND_MAGAZINE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        brandMagazine: []
      };

    case `${brandTypes.GET_BRAND_TOPIC_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        brandTopic: payload.data
      };
    case `${brandTypes.GET_BRAND_TOPIC_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        brandTopic: []
      };

    case `${brandTypes.GET_BRAND_SUBCATEGORY_STYLE_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        brandStyleActive: payload.data
      };
    case `${brandTypes.GET_BRAND_SUBCATEGORY_STYLE_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        brandStyleActive: []
      };

    case `${brandTypes.SET_FAVOURITE_BRAND_STYLE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        favouriteBrandStyle: payload.data
      };
    case `${brandTypes.SET_FAVOURITE_BRAND_STYLE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        favouriteBrandStyle: null,
      };

    case `${brandTypes.DELETE_FAVOURITE_BRAND_STYLE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        favouriteBrandStyle: payload.data
      };
    case `${brandTypes.DELETE_FAVOURITE_BRAND_STYLE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        favouriteBrandStyle: null,
      };

    default:
      return state;
  }
};

export default reducer;
