import * as searchTypes from './search.type';

const initialState = {
  searchData: null,
  error: null,
  isLoading: false,
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${searchTypes.GET_SEARCH_BY_TEXT_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${searchTypes.GET_SEARCH_BY_TEXT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        searchData: payload.data,
      };
    case `${searchTypes.GET_SEARCH_BY_TEXT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        searchData: null,
      };

    default:
      return state;
  }
};

export default reducer;
