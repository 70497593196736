import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authAction, homeAction } from "../../../redux/modules";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Styles
import "./styles.scss";

const HomeAnnouncement = () => {
  const dispatch = useDispatch();
  const announcementList = useSelector((store) => store.home.announcementList);
  const authUser = useSelector((store) => store.auth.authUser);

  useEffect(() => {
    dispatch(homeAction.getAnnouncementList("homepage"));
  
    return () => {};
  }, [dispatch]);

  return (
      <section>
        <Container>
          <Row>
            <Col>
              <Swiper
                modules={[Navigation, Pagination]}
                navigation={true}
                className="mySwiper home-swiper"
              >
                {announcementList
                  ? announcementList.map((item, index) => {
                      const key = item.id || index;
                      return (
                        <React.Fragment key={key}>
                          {authUser.roleId === 6 && (index === 0 || index === 1) && (
                            <SwiperSlide key={`role6-${key}`}>
                              {item.announcementContentList[0] && !item.url && (
                                <Row className="justify-content-center">
                                  <Col xs={12} sm={12}>
                                    <img
                                      src={item.announcementContentList[0]}
                                      className="img-fluid"
                                      alt="announcement"
                                    />
                                  </Col>
                                </Row>
                              )}

                              {item.announcementContentList[0] && item.url && (
                                <Row className="justify-content-center">
                                  <Col xs={12} sm={12} lg={12}>
                                    <a
                                      href={item.url}
                                      target={item.newTab ? "_blank" : "_self"}
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={item.announcementContentList[0]}
                                        className="img-fluid"
                                        alt="announcement"
                                      />
                                    </a>
                                  </Col>
                                </Row>
                              )}
                            </SwiperSlide>
                          )}

                          {authUser.roleId === 5 && (index === 2 || index === 3) && (
                            <SwiperSlide key={`role5-${key}`}>
                              {item.announcementContentList[0] && !item.url && (
                                <Row className="justify-content-center">
                                  <Col xs={12} sm={12}>
                                    <img
                                      src={item.announcementContentList[0]}
                                      className="img-fluid"
                                      alt="announcement"
                                    />
                                  </Col>
                                </Row>
                              )}

                              {item.announcementContentList[0] && item.url && (
                                <Row className="justify-content-center">
                                  <Col xs={12} sm={12} lg={12}>
                                    <a
                                      href={item.url}
                                      target={item.newTab ? "_blank" : "_self"}
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={item.announcementContentList[0]}
                                        className="img-fluid"
                                        alt="announcement"
                                      />
                                    </a>
                                  </Col>
                                </Row>
                              )}
                            </SwiperSlide>
                          )}
                        </React.Fragment>
                      );
                    })
                  : null}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
  );
};
export default HomeAnnouncement;
