import * as aboutTypes from './about.type';
import { API } from '../../../services/http.service';

const REQUEST_URL = '';
const config = {
  headers: { 'Content-Type': 'application/json-application' },
};

export const getAboutUs = () => async dispatch => {
  dispatch({ type: aboutTypes.GET_ABOUT_US_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/employee/get/all/aboutUsCategory?size=9999`, config);
    dispatch({
      type: aboutTypes.GET_ABOUT_US_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: aboutTypes.GET_ABOUT_US_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const getAboutUsByType = (type="faq") => async dispatch => {
  dispatch({ type: aboutTypes.GET_ABOUT_US_BY_TYPE_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/employee/get/aboutUs/${type}`, config);
    dispatch({
      type: aboutTypes.GET_ABOUT_US_BY_TYPE_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: aboutTypes.GET_ABOUT_US_BY_TYPE_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const getFooterAboutUs = () => async dispatch => {
  dispatch({ type: aboutTypes.GET_FOOTER_ABOUT_US_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/employee/get/footerAboutUs`, config);
    dispatch({
      type: aboutTypes.GET_FOOTER_ABOUT_US_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: aboutTypes.GET_FOOTER_ABOUT_US_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const getFooterAboutUsType = type => async dispatch => {
  dispatch({ type: aboutTypes.GET_FOOTER_ABOUT_US_LIST_BY_TYPE_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/employee/get/aboutUs/${type}`, config);
    dispatch({
      type: aboutTypes.GET_FOOTER_ABOUT_US_LIST_BY_TYPE_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: aboutTypes.GET_FOOTER_ABOUT_US_LIST_BY_TYPE_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};