import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { aboutAction } from "../../redux/modules";

// Header Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

const Content = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [activePage, setActivePage] = useState(null);
  const [accordionData, setAccordionData] = useState([]);
  const [extraContentBefore, setExtraContentBefore] = useState([]);
  const [extraContentAfter, setExtraContentAfter] = useState([]);
  const [aboutUsByType, setAboutUsByType] = useState([]);

  const footerAboutUs = useSelector((store) => store.about.footerAboutUs);
  // const aboutUsByType = useSelector((store) => store.about.aboutUsByType);

  useEffect(() => {
    if (footerAboutUs?.length) {
      const slug = location.pathname.split("/static/")[1];
      setActivePage(footerAboutUs.find((item) => item.slug === slug));
    }
  }, [footerAboutUs, location]);

  useEffect(() => {
    if (activePage) {
      dispatch(aboutAction.getAboutUsByType(activePage.slug)).then((res) => {
        setAboutUsByType(res.data);
      }).catch(error => {
        if(error) {
          console.log("error: ", error);
        }
        setAboutUsByType(null);
      });
    }
  }, [dispatch, activePage]);

  useEffect(() => {
    console.log("aboutUsByType: ", aboutUsByType);
    if (
      aboutUsByType?.content?.length > 0 &&
      aboutUsByType.content[0]?.aboutUsDTOList?.length > 0
    ) {
      const description =
        aboutUsByType.content[0].aboutUsDTOList[0]?.description;
      if (description) {
        const parseDescription = (desc) => {
          const items = [];
          const extraBefore = [];
          const extraAfter = [];
          const regex = /(<hr>)|(<h2>(.*?)<\/h2>)|(<h3>(.*?)<\/h3>)|(<.*?>.*?<\/.*?>)/g;
          let match;
          let currentAccordion = null;
          let previousIndex = 0;
          let isFirstHRFound = false;

          while ((match = regex.exec(desc)) !== null) {
            if (match[1] === "<hr>") {
              const preContent = desc.slice(previousIndex, match.index).trim();
              if (preContent && currentAccordion) {
                currentAccordion.content += preContent;
              } else if (preContent) {
                extraBefore.push(preContent);
              }
              if (currentAccordion) {
                items.push(currentAccordion);
                currentAccordion = null;
              }
              isFirstHRFound = true;
              previousIndex = regex.lastIndex;
            } else if (match[3]) {
              if (currentAccordion) {
                items.push(currentAccordion);
              }
              currentAccordion = { title: match[3], content: "" };
              previousIndex = regex.lastIndex;
            } else if (match[5]) {
              if (currentAccordion) {
                currentAccordion.content += match[5];
              }
              previousIndex = regex.lastIndex;
            } else if (match[6]) {
              const genericContent = match[0].trim();
              if (!isFirstHRFound) {
                extraBefore.push(genericContent);
              } else {
                extraAfter.push(genericContent);
              }
              previousIndex = regex.lastIndex;
            }
          }

          if (currentAccordion) {
            const postContent = desc.slice(previousIndex).trim();
            if (postContent) {
              currentAccordion.content += postContent;
            }
            items.push(currentAccordion);
          }

          return { items, extraBefore, extraAfter };
        };

        const { items, extraBefore, extraAfter } = parseDescription(description);
        setAccordionData(items);
        setExtraContentBefore(extraBefore);
        setExtraContentAfter(extraAfter);
      }
    }
  }, [aboutUsByType]);

  return (
    <>
      <Header />
      <main>
        <section className="bg-blue py-5 mb-5">
          <div className="container">
            <div className="d-lg-flex d-none text-white small mb-4 opacity-50">
              <NavLink to="/" className="text-white text-decoration-none me-3">
                Anasayfa
              </NavLink>
              <span className="me-3">»</span>
              {activePage && <span>{activePage.slug}</span>}
            </div>
            {activePage && (
              <h1 className="text-white mb-0">{activePage.title}</h1>
            )}
          </div>
        </section>

        <section className="mb-sm-10 mb-5">
          <div className="container">
            <div className="row gy-5">
              <div className="col-xl-3 col-lg-4 order-1">
                <div className="ps-lg-5 ps-0">
                  <ul className="list-unstyled d-grid gap-3 mb-0">
                    {footerAboutUs?.map((item) => (
                      <li key={item.id}>
                        <NavLink
                          className="text-decoration-none"
                          to={`/static/${item.slug}`}
                        >
                          <span>{item.title}</span>
                        </NavLink>
                      </li>
                    ))}
                    <li>
                      <NavLink
                        className="text-decoration-none"
                        to="/profile/messages"
                      >
                        <span>Bize Ulaşın</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-9 col-lg-8 order-0 accordions">
                {extraContentBefore.length > 0 && (
                  <div className="extra-content-before">
                    {extraContentBefore.map((content, index) => (
                      <div key={index} dangerouslySetInnerHTML={{ __html: content }} />
                    ))}
                  </div>
                )}
                <div className="accordion accordion-flush shadow-lg rounded-3 overflow-hidden mb-5">
                  {accordionData.map((item, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed lh-lg"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                        >
                          {item.title}
                        </button>
                      </h2>
                      <div
                        className="accordion-collapse collapse"
                        data-bs-parent=".accordions"
                        id={`collapse${index}`}
                      >
                        <div className="accordion-body fw-light p-4">
                          <div dangerouslySetInnerHTML={{ __html: item.content }} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {extraContentAfter.length > 0 && (
                  <div className="extra-content-after">
                    {extraContentAfter.map((content, index) => (
                      <div key={index} dangerouslySetInnerHTML={{ __html: content }} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Content;
