import { authAction } from "../modules";

/**
 * When the http status is 401, it will automatically dispatch for logout
 * @param {import('redux').Dispatch} Object
 */

export const handleAuthenticated =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.payload && action.payload.status === 401) {
      // Refresh Token
      let token = localStorage.getItem("token");
      let refreshToken = localStorage.getItem("refreshToken");

      dispatch(authAction.refreshToken(token, refreshToken))
        .then(res => {
          if (res.token) {
            localStorage.setItem('token', res.token);
            localStorage.setItem('refreshToken', res.refreshToken);

            // TODO: kullanıcı hangi sayfada ise o sayfaya gitmesi bekleniyor...
            window.location.replace('/');
          }
        })
        .catch(err => {
          console.log("err: ", err);
          dispatch(authAction.logout(localStorage.getItem('token'), localStorage.getItem('refreshToken')));
        });
    } else if (action.payload && action.payload.status === 403) {
      // Logout
      localStorage.clear();
      window.location.replace('/login');
    }
    else {
      next(action);
    }
  };
