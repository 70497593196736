export const GET_FORUM_ALL_TOPICS_PENDING = 'GET_FORUM_ALL_TOPICS_PENDING';
export const GET_FORUM_ALL_TOPICS_FULFILLED = 'GET_FORUM_ALL_TOPICS_FULFILLED';
export const GET_FORUM_ALL_TOPICS_REJECTED = 'GET_FORUM_ALL_TOPICS_REJECTED';

export const GET_FORUM_WEEKLY_TOPIC_PENDING = 'GET_FORUM_WEEKLY_TOPIC_PENDING';
export const GET_FORUM_WEEKLY_TOPIC_FULFILLED = 'GET_FORUM_WEEKLY_TOPIC_FULFILLED';
export const GET_FORUM_WEEKLY_TOPIC_REJECTED = 'GET_FORUM_WEEKLY_TOPIC_REJECTED';

export const GET_FORUM_COMMENTS_PENDING = 'GET_FORUM_COMMENTS_PENDING';
export const GET_FORUM_COMMENTS_FULFILLED = 'GET_FORUM_COMMENTS_FULFILLED';
export const GET_FORUM_COMMENTS_REJECTED = 'GET_FORUM_COMMENTS_REJECTED';

export const SET_FORUM_COMMENT_BY_ID_PENDING = 'SET_FORUM_COMMENT_BY_ID_PENDING';
export const SET_FORUM_COMMENT_BY_ID_FULFILLED = 'SET_FORUM_COMMENT_BY_ID_FULFILLED';
export const SET_FORUM_COMMENT_BY_ID_REJECTED = 'SET_FORUM_COMMENT_BY_ID_REJECTED';
