import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction, profileAction } from "../../../redux/modules";

// Toastify
import showMessage from "../../../components/ShowMessage";
import showErrMessage from "../../../components/ShowErrMessage";

// Form Validations
import { Formik } from "formik";
import * as yup from "yup";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import Form from "react-bootstrap/Form";

const AccountInfo = () => {
  const dispatch = useDispatch();
  const profileDetail = useSelector((store) => store.profile.profileDetail);
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    const reloadControl = localStorage.getItem("reControl");

    if (!reloadControl) {
      localStorage.setItem("reControl", "true");
      dispatch(userAction.setLoginedUser("logined"));
    }

    return () => {};
  }, [dispatch]);

  const handlePasswordTypeChange = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const handleChangePassword = (values) => {
    console.log("values: ", values);

    if (
      values?.newPassword?.length > 0 &&
      values?.newPasswordConfirm?.length > 0
    ) {
      dispatch(
        profileAction.profileChangePassword(
          values.newPassword,
          values.newPasswordConfirm
        )
      )
        .then((e) => {
          showMessage("Şifre Değiştirildi!");
        })
        .catch((err) => {
          console.log("err", err.message);
          showErrMessage(` ${err.message}`);
        });
    }
  };

  const schema = yup.object({
    newPassword: yup.string().required("Lütfen Şifrenizi Giriniz!").matches(
      //"/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/",
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.,!])(?=\\S+$).{8,}$",
      "Şifreniz 8 Karakter, Bir Büyük Harf, Bir Küçük Harf, Bir Rakam ve Bir Özel Harf Karakteri İçermelidir!"
    ),
    newPasswordConfirm: yup
      .string()
      .required("Lütfen Şifre Tekrarı Giriniz!")
      .oneOf([yup.ref("newPassword"), null], "Şifreler Eşleşmiyor!"),
  });

  return (
    <React.Fragment>
      <h2 className="mb-sm-6 mb-4">Hesap Bilgilerim</h2>
      <p className="mb-sm-5 mb-4 fw-light">
        Aşağıdaki bilgilerde değişiklik yapabilmek için 0850 532 5787 numaralı
        Çağrı Merkezi’mizi arayabilir, Satış Belgesi numaranızı ve bayi kodunuzu
        kullanarak müşteri temsilcimizle görüşebilirsiniz. Şifre değiştirme
        bölümü aracılığıyla şifrenizi değiştirebilirsiniz.
      </p>
      <div className="row gy-5 row-cols-md-3 row-cols-2 pb-sm-5 pb-4 border-bottom mb-sm-5 mb-4">
        {profileDetail && (
          <>
            {profileDetail.dealerSignName && (
              <div className="col">
                <h6 className="opacity-50">Bayi Tabela Adı</h6>
                <p className="mb-0">{profileDetail.dealerSignName}</p>
              </div>
            )}
            {profileDetail?.employeeDTO?.name && (
              <div className="col">
                <h6 className="opacity-50">Üye Adı</h6>
                <p className="mb-0">{profileDetail.employeeDTO.name}</p>
              </div>
            )}
            {profileDetail?.employeeDTO.surname && (
              <div className="col">
                <h6 className="opacity-50">Üye Soyadı</h6>
                <p className="mb-0">{profileDetail.employeeDTO.surname}</p>
              </div>
            )}
            {profileDetail.salesDocumentNumber && (
              <div className="col">
                <h6 className="opacity-50">Satış Belgesi Numarası</h6>
                <p className="mb-0">{profileDetail.salesDocumentNumbe}</p>
              </div>
            )}
            {profileDetail.uniqueField && (
              <div className="col">
                <h6 className="opacity-50">Erc Kodu</h6>
                <p className="mb-0">{profileDetail.uniqueField}</p>
              </div>
            )}
            {profileDetail.employeeDTO.mobileNo && (
              <div className="col">
                <h6 className="opacity-50">Cep Telefonu</h6>
                <p className="mb-0">{profileDetail.employeeDTO.mobileNo}</p>
              </div>
            )}
          </>
        )}
      </div>

      <Formik
        validationSchema={schema}
        onSubmit={(values) => handleChangePassword(values)}
        initialValues={{
          newPassword: "",
          newPasswordConfirm: "",
          changePasswordType: "THREE",
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <h5 className="text-blue mb-sm-5 mb-4">Şifre Değiştirme</h5>
            <div className="row gy-3 row-cols-md-3 row-cols-1 align-items-start">
              <div className="col">
                <div className="form-floating">
                  <Form.Group controlId="validationCustom09">
                    <Form.Label>Yeni Şifre</Form.Label>
                    <Form.Control
                      className="form-control"
                      autoComplete="chrome-off"
                      type={passwordType}
                      placeholder="Yeni Şifre"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="newPassword"
                      value={values.newPassword}
                      isInvalid={
                        !!errors.newPassword &&
                        touched.newPassword &&
                        !!errors.newPassword
                      }
                    />

                    <Form.Control.Feedback className="FeedBack" type="invalid">
                      {errors.newPassword &&
                        touched.newPassword &&
                        errors.newPassword}
                    </Form.Control.Feedback>

                    {(values.newPassword?.length > 0 && !errors.newPassword) &&(
                      <>
                        <FontAwesomeIcon
                          icon={faEye}
                          className={
                            passwordType === "password"
                              ? "d-none password-eye"
                              : "password-eye"
                          }
                          onClick={handlePasswordTypeChange}
                        />
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          className={
                            passwordType === "text"
                              ? "d-none password-eye-slash"
                              : "password-eye-slash"
                          }
                          onClick={handlePasswordTypeChange}
                        />
                      </>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="col">
                <div className="form-floating">
                  <Form.Group controlId="validationCustom10">
                    <Form.Label>Yeni Şifre Tekrar</Form.Label>
                    <Form.Control
                      className="form-control"
                      autoComplete="chrome-off"
                      type={passwordType}
                      placeholder="Yeni Şifre Tekrar"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="newPasswordConfirm"
                      value={values.newPasswordConfirm}
                      isInvalid={
                        !!errors.newPasswordConfirm &&
                        touched.newPasswordConfirm &&
                        !!errors.newPasswordConfirm
                      }
                    />

                    <Form.Control.Feedback className="FeedBack" type="invalid">
                      {errors.newPasswordConfirm &&
                        touched.newPasswordConfirm &&
                        errors.newPasswordConfirm}
                    </Form.Control.Feedback>

                    {(values.newPasswordConfirm?.length > 0 && !errors.newPasswordConfirm) && (
                      <>
                        <FontAwesomeIcon
                          icon={faEye}
                          className={
                            passwordType === "password"
                              ? "d-none password-eye"
                              : "password-eye"
                          }
                          onClick={handlePasswordTypeChange}
                        />
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          className={
                            passwordType === "text"
                              ? "d-none password-eye-slash"
                              : "password-eye-slash"
                          }
                          onClick={handlePasswordTypeChange}
                        />
                      </>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="col">
                <button
                  type="submit"
                  style={{ marginTop: "28px"}}
                  className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 w-100"
                >
                  Değiştir
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default AccountInfo;
