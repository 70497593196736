export const GET_MAGAZINE_LIST_PENDING = 'GET_MAGAZINE_LIST_PENDING';
export const GET_MAGAZINE_LIST_FULFILLED = 'GET_MAGAZINE_LIST_FULFILLED';
export const GET_MAGAZINE_LIST_REJECTED = 'GET_MAGAZINE_LIST_REJECTED';

export const GET_MAGAZINE_CATEGORY_LIST_PENDING = 'GET_MAGAZINE_CATEGORY_LIST_PENDING';
export const GET_MAGAZINE_CATEGORY_LIST_FULFILLED = 'GET_MAGAZINE_CATEGORY_LIST_FULFILLED';
export const GET_MAGAZINE_CATEGORY_LIST_REJECTED = 'GET_MAGAZINE_CATEGORY_LIST_REJECTED';

export const GET_MAGAZINE_CATEGORY_BY_ID_PENDING = 'GET_MAGAZINE_CATEGORY_BY_ID_PENDING';
export const GET_MAGAZINE_CATEGORY_BY_ID_FULFILLED = 'GET_MAGAZINE_CATEGORY_BY_ID_FULFILLED';
export const GET_MAGAZINE_CATEGORY_BY_ID_REJECTED = 'GET_MAGAZINE_CATEGORY_BY_ID_REJECTED';

export const GET_MAGAZINE_SUB_CATEGORY_BY_ID_PENDING = 'GET_MAGAZINE_SUB_CATEGORY_BY_ID_PENDING';
export const GET_MAGAZINE_SUB_CATEGORY_BY_ID_FULFILLED = 'GET_MAGAZINE_SUB_CATEGORY_BY_ID_FULFILLED';
export const GET_MAGAZINE_SUB_CATEGORY_BY_ID_REJECTED = 'GET_MAGAZINE_SUB_CATEGORY_BY_ID_REJECTED';

export const GET_TV_LIST_PENDING = 'GET_TV_LIST_PENDING';
export const GET_TV_LIST_FULFILLED = 'GET_TV_LIST_FULFILLED';
export const GET_TV_LIST_REJECTED = 'GET_TV_LIST_REJECTED';

export const GET_TV_CATEGORY_BY_ID_PENDING = 'GET_TV_CATEGORY_BY_ID_PENDING';
export const GET_TV_CATEGORY_BY_ID_FULFILLED = 'GET_TV_CATEGORY_BY_ID_FULFILLED';
export const GET_TV_CATEGORY_BY_ID_REJECTED = 'GET_TV_CATEGORY_BY_ID_REJECTED';

export const GET_TV_SUB_CATEGORY_BY_ID_PENDING = 'GET_TV_SUB_CATEGORY_BY_ID_PENDING';
export const GET_TV_SUB_CATEGORY_BY_ID_FULFILLED = 'GET_TV_SUB_CATEGORY_BY_ID_FULFILLED';
export const GET_TV_SUB_CATEGORY_BY_ID_REJECTED = 'GET_TV_SUB_CATEGORY_BY_ID_REJECTED';