import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "../../../redux/modules";
import showErrMessage from "../../../components/ShowErrMessage";
import showMessage from "../../../components/ShowMessage";

const Messages = () => {
  const dispatch = useDispatch();
  const subjectList = useSelector((store) => store.profile.subjectList);

  // Upload Ref
  const uploadInput = useRef();
  const [uploadInputValidation, setUploadInputValidation] = useState(false);

  // Upload Refs
  const uploadInputRefs = useRef([]);

  const [pageState, setPageState] = useState({
    newMessageLengthLeft: 1000,
    responseMessages: [],
    isLoading: false,
    message: "",
    messageSubject: "",
    messageMain: "",
  });

  useEffect(() => {
    dispatch(profileAction.getSubjectList());
  
    return () => {}
  }, [dispatch]);

  useEffect(() => {
    if (subjectList) {
      setPageState({
        ...pageState,
        responseMessages: subjectList,
      })
    }

    return () => {}
  }, [subjectList]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 4 * 1024 * 1024;

    if (file?.size > maxSize) {
      setUploadInputValidation(true);
    } else {
      setUploadInputValidation(false);
    }
  };

  const handleNewMessageByIdSubmit = (event, contactSubjectId, index) => {
    event.preventDefault();

    if (contactSubjectId && pageState?.messageMain) {
      setPageState((prevState) => ({ ...prevState, isLoading: true }));

      const file = uploadInputRefs.current[index].files[0];

      dispatch(profileAction.sendMessageById(contactSubjectId, pageState?.messageMain, file))
        .then(() => {
          showMessage("Mesaj Gönderildi!");
          setPageState((prevState) => ({ ...prevState, isLoading: false, messageMain: "" }));
          dispatch(profileAction.getSubjectList());
        })
        .catch((err) => {
          console.log("err: ", err);
          showErrMessage("Mesaj Gönderilemedi. Bir Hata Oluştu!");
          setPageState((prevState) => ({ ...prevState, isLoading: false }));
        });
    }
  };  

  const handleNewMessageSubmit = (event) => {
    event.preventDefault();

    if (pageState.message && pageState.messageSubject) {
      setPageState((prevState) => ({ ...prevState, isLoading: true }));

      dispatch(profileAction.sendMessage(pageState.message, pageState.messageSubject, uploadInput.current.files[0]))
        .then(() => {
          showMessage("Mesaj Gönderildi!");
          setPageState((prevState) => ({ ...prevState, isLoading: false, message: "", messageSubject: "" }));
          dispatch(profileAction.getSubjectList());
        })
        .catch((err) => {
          showErrMessage("Mesaj Gönderilemedi. Bir Hata Oluştu!");
          setPageState((prevState) => ({ ...prevState, isLoading: false }));
        });
    }
  };

  const handleNewMessageChange = (event) => {
    const { value, name } = event.target;
    if (name === "message") {
      setPageState((prevState) => ({
        ...prevState,
        newMessageLengthLeft: 1000 - value.length,
        message: value,
      }));
    } else if (name === "messageSubject") {
      setPageState((prevState) => ({
        ...prevState,
        messageSubject: value,
      }));
    }
  };

  const handleMessageClick = (event, id) => {
    dispatch(profileAction.getSubjectById(id));
    setPageState((prevState) => ({
      ...prevState,
      messageMain: ""
    }));
  };

  return (
    <React.Fragment>
      <h2 className="mb-sm-6 mb-4">Mesajlarım</h2>

      <h5 className="text-blue mb-sm-5 mb-4">Bize Ulaşın</h5>
      <form className="mb-sm-6 mb-4" onSubmit={(event) => handleNewMessageSubmit(event)}>
        <div className="row g-5">
          <div className="col-md-6 order-md-0 order-1">
            <div className="form-floating mb-4">
              <select
                className="form-select"
                name="messageSubject"
                required
                value={pageState?.messageSubject}
                onChange={(event) => handleNewMessageChange(event)}
              >
                <option value="">Seçiniz</option>
                <option value="Genel">Genel</option>
                <option value="Şikayet/Dükkan">Şikayet/Dükkan</option>
                <option value="Şikayet/Yarışmalar">Şikayet/Yarışmalar</option>
                <option value="Şikayet/Puan Kazanımı ">Şikayet/Puan Kazanımı</option>
                <option value="Şikayet/Diğer">Şikayet/Diğer</option>
                <option value="Öneri/Dükkan">Öneri/Dükkan</option>
                <option value="Öneri/Yarışmalar">Öneri/Yarışmalar</option>
                <option value="Öneri/Puan Kazanımı">Öneri/Puan Kazanımı</option>
                <option value="Öneri/Diğer">Öneri/Diğer</option>
                <option value="Bilgi">Bilgi</option>
                <option value="Memnuniyet">Memnuniyet</option>
              </select>
              <label>Konu</label>
            </div>
            <div className="mb-2">
              <textarea
                className="form-control"
                placeholder="Mesajınız"
                style={{ height: "100px" }}
                name="message"
                maxLength="1000"
                onChange={(event) => handleNewMessageChange(event)}
                required
                value={pageState?.message}
              ></textarea>
            </div>
            <span className={`small d-block text-end mb-4${pageState.newMessageLengthLeft <= 100 ? " text-red" : ""}`}>
              {pageState.newMessageLengthLeft} karakter kaldı.
            </span>
            <div className="mb-4">
              <label className="form-label">Dosya / Fotoğraf Ekleyin</label>
              <input className="form-control" type="file" name="imgSrc" ref={uploadInput} onChange={(event) => handleFileChange(event)} />
              {uploadInputValidation && (
                <div className="mt-3 text-red">Dosya boyutu maksimum 4MB olabilir!</div>
              )}
            </div>
            <button type="submit" className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 w-100" disabled={pageState.isLoading || uploadInputValidation}>
              {pageState.isLoading ? <span className="spinner-border spinner-border-sm me-3"></span> : null}
              Gönder
            </button>
          </div>
          <div className="col-md-6 order-md-1 order-0">
            <p className="mb-0 fw-light lh-lg">
              Sizi dinliyoruz. Her türlü görüş, öneri ve isteklerinizi Bize Ulaşın bölümü aracılığıyla bizimle
              paylaşabilirsiniz. Bizim Kahramanlar ile ilgili sorularınız için 0850 532 5787 numaralı Çağrı Merkezimizi
              arayarak hafta içi 09.00-18.00 saatleri arasında bize ulaşabilirsiniz.
            </p>
          </div>
        </div>
      </form>

      <h5 className="text-blue mb-sm-5 mb-4">Tüm Mesajlarım</h5>
      {pageState.responseMessages.length > 0 ? (
        <div className="accordion accordion-flush shadow-lg rounded-3 overflow-hidden mb-5">
          {pageState.responseMessages.map((item, i) => (
            <div className="accordion-item" key={i}>
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed lh-lg d-flex"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${i}`}
                  onClick={(event) => handleMessageClick(event, item.id)}
                >
                  <span className="small opacity-75">{item.dateBeginString}</span>
                  <span className="ms-4">{item.message}</span>
                  {item.unReadListMessages ? (
                    <div className="bg-orange text-white px-3 py-0 ms-3 rounded-pill small">
                      {item.unReadListMessages.length}
                    </div>
                  ) : null}
                </button>
              </h2>
              <div className="accordion-collapse collapse" data-bs-parent=".accordion" id={`collapse${i}`}>
                <div className="accordion-body fw-light p-4 d-flex flex-column">
                  {item.contactMessageDTOList.map((message, j) => (
                    <React.Fragment key={j}>
                      <div
                        className={`px-3 py-2 mb-2 rounded-3 ${
                          message.unreadMessage === true
                            ? "ms-auto bg-green text-white"
                            : "me-auto bg-gray-lighter"
                        }`}
                      >
                        {message.icon ? (
                          <div className="my-2">
                            <img src={`${process.env.REACT_APP_IMAGES_BASE_URL}/${message.icon}`} alt="" className="rounded-3" style={{ maxWidth: "100%" }} />
                          </div>
                        ) : null}
                        {message.message}
                      </div>
                      <div
                        className={`mb-3 opacity-50 ${
                          message.unreadMessage === true ? "ms-auto" : "me-auto"
                        }`}
                        style={{ fontSize: "12px" }}
                      >
                        {message.createDate}
                      </div>

                      {message?.contentUrlList?.length > 0 && message?.contentUrlList.map((image, imageIndex) => {
                        return (
                          <a href={image} key={imageIndex} target="_blank" rel="noreferrer" className="mb-4">
                            <img src={image} alt="" className="img-fluid" />
                          </a>
                        )
                      })}
                      
                    </React.Fragment>
                  ))}
                  
                  {item.contactStatu === "AÇIK" ? (
                    <div className="bg-gray-lightest rounded-3 p-4">
                      <h5 className="mb-4 text-blue">Cevapla</h5>
                      <form onSubmit={(event) => handleNewMessageByIdSubmit(event, item.id, i)}>
                        <div className="mb-3">
                          <textarea
                            className="form-control"
                            placeholder="Mesajınız"
                            style={{ height: "100px" }}
                            name="messageMain"
                            maxLength="1000"
                            required
                            value={pageState?.messageMain}
                            onChange={(event) =>  setPageState((prevState) => ({ ...prevState, messageMain: event.target.value }))}
                          ></textarea>
                        </div>
                        <div className="mb-4 d-flex align-items-center">
                          <label className="form-label mb-0 me-4 text-nowrap small">
                            <strong>Dosya / Fotoğraf Ekleyin</strong>
                          </label>
                          <input className="form-control" type="file" name="imgSrcMain" ref={(el) => (uploadInputRefs.current[i] = el)}  />
                        </div>
                        <button type="submit" className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 w-100" disabled={pageState.isLoading}>
                          {pageState.isLoading ? <span className="spinner-border spinner-border-sm me-3"></span> : null}
                          Gönder
                        </button>
                      </form>
                    </div>
                  ) : (
                    <div className="text-center small py-4 text-red">
                      Bu mesajınız kapatılmıştır. Yeni bir mesaj başlatarak bize ulaşabilirsiniz.
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center">
          <h2 className="mb-4 opacity-75">Hiç Mesajınız yok</h2>
        </div>
      )}
    </React.Fragment>
  );
};

export default Messages;
