import React from "react";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const NotFound = ({ history }) => {
  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col>
          <div className="not-found-page text-center pt-5 pb-5">
            <h3 className="p-0 m-0">Üzgünüz. Aradığınız sayfaya şuanda ulaşılamıyor.</h3>
            <h3 className="p-0 m-0 pt-2">Lütfen daha sonra tekrar deneyiniz.</h3>

            <button
              className="mt-4 btn btn-primary"
              onClick={() => history.replace("/")}
            >
              Ana Sayfa
            </button>
          </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default NotFound;
