import * as homeTypes from "./home.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getAnnouncementList = type => async dispatch => {
  dispatch({ type: homeTypes.GET_ANNOUNCEMENTLIST_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/announcement/announcementList/${type}`,
      config
    );
    dispatch({
      type: homeTypes.GET_ANNOUNCEMENTLIST_FULFILLED,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: homeTypes.GET_ANNOUNCEMENTLIST_REJECTED,
      payload: error
    });
  }
};

export const getDisclaimerByFirmId = (id, employeeId) => async dispatch => {
  dispatch({ type: homeTypes.GET_DISCLAIMER_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/disclaimer/${id}/${employeeId}`,
      config
    );
    dispatch({
      type: homeTypes.GET_DISCLAIMER_BY_FIRMID_FULFILLED,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: homeTypes.GET_DISCLAIMER_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const changeOption = selectedOption => async dispatch => {
  dispatch({ type: homeTypes.SELECTED_OPTION, payload: selectedOption });
};

export const saveDisclaimer = (
  disclaimer,
  employeeFirmId,
  firmId
) => async dispatch => {
  dispatch({ type: homeTypes.SAVE_DISCLAIMER_CHECK_PENDING });

  const data = { disclaimer, employeeFirmId, firmId };
  try {
    const response = await API.post(`/disclaimer/save`, data);

    dispatch({
      type: homeTypes.SAVE_DISCLAIMER_CHECK_FULFILLED,
      payload: response.data
    });

    //    dispatch(getDisclaimerByFirmId(firmId));
  } catch (error) {
    dispatch({
      type: homeTypes.SAVE_DISCLAIMER_CHECK_REJECTED,
      payload: error
    });
  }
};

export const getSpecialActivityByFirmId = id => async dispatch => {
  dispatch({ type: homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/specialActivityList/${id}`,
      config
    );
    dispatch({
      type: homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_FULFILLED,
      payload: response.data
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getBrandList = () => async dispatch => {
  dispatch({ type: homeTypes.GET_BRAND_LIST_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/brand/brandList`, config);
    dispatch({
      type: homeTypes.GET_BRAND_LIST_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.GET_BRAND_LIST_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getProductList = (
  type="updateDate",
  sortDirection = "DESC",
  score = 0,
  size = 21,
  page = 0,
  categoryId = 0,
  subCategoryId = 0
) => async dispatch => {
  dispatch({ type: homeTypes.GET_PRODUCT_LIST_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/catalog/productList/${categoryId}/${subCategoryId}/${type}/${sortDirection}/${score}?size=${size}&page=${page}`,
      config
    );
    dispatch({
      type: homeTypes.GET_PRODUCT_LIST_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.GET_PRODUCT_LIST_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getHomepageSurvey = () => async dispatch => {
  dispatch({ type: homeTypes.GET_SURVEY_LIST_HOMEPAGE_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/survey/get/all/surveys`,
      config
    );
    dispatch({
      type: homeTypes.GET_SURVEY_LIST_HOMEPAGE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.GET_SURVEY_LIST_HOMEPAGE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const setHomepageSurvey = (
  surveyId,
  surveyChoiceId
) => async dispatch => {
  dispatch({ type: homeTypes.SET_SURVEY_ANSWER_HOMEPAGE_PENDING });
  const data = {
    surveyId: surveyId,
    surveyChoiceId: surveyChoiceId
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/survey/save/survey/answer`,
      data,
      config
    );
    dispatch({
      type: homeTypes.SET_SURVEY_ANSWER_HOMEPAGE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.SET_SURVEY_ANSWER_HOMEPAGE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const addToCart = (productId, productCount = 1) => async dispatch => {
  dispatch({ type: homeTypes.SET_ADD_TO_CART_PENDING });

  const data = {
    cartType: "EMPLOYEE",
    productCount: productCount,
    productId: productId
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/cart/add/product`,
      data,
      config
    );
    dispatch({
      type: homeTypes.SET_ADD_TO_CART_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.SET_ADD_TO_CART_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getSurveyResult = surveyId => async dispatch => {
  dispatch({ type: homeTypes.GET_SURVEY_RESULT_PENDING });

  const data = {
    surveyId: surveyId
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/survey/get/survey/results`,
      data,
      config
    );
    dispatch({
      type: homeTypes.GET_SURVEY_RESULT_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.GET_SURVEY_RESULT_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getLeaderboard = () => async dispatch => {
  dispatch({ type: homeTypes.GET_HOMEPAGE_LEADERBOARD });

  try {
    const response = await API.get(
      `${REQUEST_URL}/leaderboard/homeBoard`,
      config
    );
    dispatch({
      type: homeTypes.GET_HOMEPAGE_LEADERBOARD,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.GET_HOMEPAGE_LEADERBOARD,
      payload: error
    });
    return Promise.reject(error);
  }
};
