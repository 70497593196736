import React from "react";
import ReactLoading from "react-loading";

const GlobalLoader = isLoading => {
  return (
    <>
      {isLoading.isLoading === true || isLoading === true ? (
        <div style={{ textAlign: "-webkit-center", position: 'fixed', top: '50%', left: '50%', marginLeft: '-32px', marginTop: '-32px' }}>
          <ReactLoading type="spinningBubbles" color="#05aa5d" />
        </div>
      ) : null}
    </>
  );
};

export default GlobalLoader;
