import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userAction } from '../../redux/modules';

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Home Components
import HomeAnnouncement from './HomeAnnouncement';
import HomeProduct from './HomeProduct';
import HomeArrangement from './HomeArrangement';
import HomeSurvey from './HomeSurvey';
import HomeGames from './HomeGames';

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const reloadControl = localStorage.getItem('reControl');

    if (!reloadControl) {
      localStorage.setItem('reControl', 'true');
      dispatch(userAction.setLoginedUser('logined'));
    }

    return () => {};
  }, [dispatch]);

  return (
    <>
      <Header />
      <HomeAnnouncement />
      <HomeProduct />
      <HomeArrangement />
      <HomeSurvey />
      <HomeGames />
      <Footer />
    </>
  );
};

export default Home;
