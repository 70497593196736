export const GET_ACADEMY_LIST_PENDING = 'GET_ACADEMY_LIST_PENDING';
export const GET_ACADEMY_LIST_FULFILLED = 'GET_ACADEMY_LIST_FULFILLED';
export const GET_ACADEMY_LIST_REJECTED = 'GET_ACADEMY_LIST_REJECTED';

export const GET_ACADEMY_CATEGORY_LIST_PENDING = 'GET_ACADEMY_CATEGORY_LIST_PENDING';
export const GET_ACADEMY_CATEGORY_LIST_FULFILLED = 'GET_ACADEMY_CATEGORY_LIST_FULFILLED';
export const GET_ACADEMY_CATEGORY_LIST_REJECTED = 'GET_ACADEMY_CATEGORY_LIST_REJECTED';

export const POST_ACADEMY_CATEGORY_FILTER_PENDING = 'POST_ACADEMY_CATEGORY_FILTER_PENDING';
export const POST_ACADEMY_CATEGORY_FILTER_FULFILLED = 'POST_ACADEMY_CATEGORY_FILTER_FULFILLED';
export const POST_ACADEMY_CATEGORY_FILTER_REJECTED = 'POST_ACADEMY_CATEGORY_FILTER_REJECTED';

export const GET_ACADEMY_DETAIL_LIST_PENDING = 'GET_ACADEMY_DETAIL_LIST_PENDING';
export const GET_ACADEMY_DETAIL_LIST_FULFILLED = 'GET_ACADEMY_DETAIL_LIST_FULFILLED';
export const GET_ACADEMY_DETAIL_LIST_REJECTED = 'GET_ACADEMY_DETAIL_LIST_REJECTED';