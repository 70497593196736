/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesAction } from "../../redux/modules";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

// Styles
import "./styles.scss";

// Images
import BgDots from "../../assets/images/games/bg-pattern.png";
import Banner from "../../assets/images/games/guess-picture/guess-picture-banner.png";
import Girls from "../../assets/images/games/lucky-wheel/girls.png";

// CountdownCircleTimer
import { CountdownCircleTimer } from "react-countdown-circle-timer";

// Toastify
import showErrMessage from "../../components/ShowErrMessage";

const GameWordPuzzles = () => {
  const dispatch = useDispatch();
  
  // Game Guess Picture
  const guessPicture = useSelector((store) => store.games.guessPicture);
  
  // Game Guess Picture Control
  const guessPictureControl = useSelector((store) => store.games.guessPictureControl);
  const guessPictureStart = useSelector((store) => store.games.guessPictureStart);
  const guessPictureOpenBox = useSelector((store) => store.games.guessPictureOpenBox);

  const [guessPictureStartState, setGuessPictureStartState] = useState(null);
  const [puzzleImagesState, setPuzzleImagesState] = useState(null);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [points, setPoints] = useState(900);

  const [gameStarted, setGameStarted] = useState(false);
  const [isFinish, setIsFinish] = useState(false);

  const [hint, setHint] = useState(6);

  // Response Message
  const [responseMessage, setResponseMessage] = useState("");
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false)

  // Timer Defaults
  const [timerColors, setTimerColors] = useState(["#76e17b", "#F7B801"]);
  const [timerColorsTime, setTimerColorsTime] = useState([90, 0]);
  const [timerDuration, setTimerDuration] = useState(0);

  // Game Is On?
  const [gameIsOn, setGameIsOn] = useState(false);

  const [btnDisabled, setBtnDisabled] = useState(false);


  // CountdownTimer When Complete
  const handleTimerComplete = () => {
    dispatch(gamesAction.guessPictureAnswer(guessPicture?.id)).then((res) => {
      console.log("res: ", res);
    }).catch(err => {
      console.log("err: ", err);
    });
  };

  const handleBeginGame = () => {
    if (guessPicture) {
      // Start Guess Picture
      dispatch(gamesAction.guessPictureStart()).then((res) => {
        if (res.isHttpSuccess) {
          setGameStarted(true);
          setGuessPictureStartState(res.data);
          
          // After Start Get Control Service
          // dispatch(gamesAction.guessPictureControl());
        }
      }).catch((err) => {
        // Error
        if (err.isHttpError) {
          console.log(err.isHttpError);
        }
      });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setBtnDisabled(true);

    if (selectedAnswer) {
      dispatch(gamesAction.guessPictureAnswer(guessPicture?.id, selectedAnswer, points)).then((response) => {
        // Correct Answer
        if (response.isHttpSuccess) {
          console.log("response: ", response);

          setResponseMessage(response.data.responseText);
          setIsFinish(true);

          if (response.data.answerTrue === true) {
            setIsCorrectAnswer(true);
          } else if (response.data.answerFalse === true) {
            setIsCorrectAnswer(false);
          } else if (response.data.answerTimeOut === true) {
            setIsTimeout(true);
          }
          
          // Timer = 0 
          setTimerDuration(0);
        }
      }).catch(err => {
        console.log("error: ", err);
      });
    } else {
      showErrMessage("Lütfen bir cevap seçiniz!");
      setBtnDisabled(false);
    }
  }

  const handleOpenBox = (e) => {
    e.preventDefault();
    console.log("Open Box");

    dispatch(gamesAction.guessPictureOpenBox(guessPicture?.id)).then(res => {
      // Calculate prize points
      if (res.isHttpSuccess) {
        setPoints( Math.floor((guessPicture?.points / guessPicture?.partSize) * ( guessPicture?.partSize - guessPictureStartState?.guessPicturePartDTOList?.length)) );
      }
    }).catch(err => {
      console.log("err: ", err);
      if (err.isHttpError && err.status === 422) {
        showErrMessage(err.message);
      }
    });
  }

  useEffect(() => {
    dispatch(gamesAction.guessPicture());
    dispatch(gamesAction.guessPictureControl());

    return () => {};
  }, []);

  useEffect(() => {
    if(guessPicture) {
      setTimerDuration(guessPicture.remainTime);
    }
  }, [guessPicture]);

  // Control
  useEffect(() => {
    if (guessPictureControl) {
      setTimerColorsTime([guessPictureControl.remainTime, 0]);
      if(guessPictureControl.remainTime > 0) {
        setGameStarted(guessPictureControl.gameIsOn);
      }

      if (guessPictureControl?.gameIsOn === true && guessPictureControl?.remainTime > 0) {
        handleBeginGame();
      }   
    }
  }, [guessPictureControl]);

  useEffect(() => {
    if (guessPictureStartState?.currentPoints) {
      setPoints(guessPictureStartState?.currentPoints);
    }

    if (guessPictureStartState) {
      if (guessPictureStartState?.guessPicturePartDTOList?.length) {
        setHint(7 - guessPictureStartState?.guessPicturePartDTOList?.length);
      }
    }

    return () => {};
  }, [guessPictureStartState]);

  // Gues Picture Merge
  useEffect(() => {
    if (guessPictureStart?.guessPicturePartDTOList && guessPictureOpenBox?.length > 0) {
      const mergedGuessPictureStart = {
        ...guessPictureStart,
        guessPicturePartDTOList: [...guessPictureOpenBox]
      }

      setGuessPictureStartState(mergedGuessPictureStart);
    }
  }, [guessPictureStart, guessPictureOpenBox]);

  // Point Calculation
  useEffect(() => {
    if (guessPicture && guessPictureStartState) {
      setPoints( Math.floor((guessPicture?.points / guessPicture?.partSize) * (guessPicture?.partSize - guessPictureStartState?.guessPicturePartDTOList?.length)) );
    }
  }, [guessPicture, guessPictureStartState]);

 
  // Puzzle Calculation
  useEffect(() => {
    // Default puzzle images array
    const defaultImages = Array(9).fill(null);

    // Function to populate puzzle images
    const populatePuzzleImages = (list) => {
      list.forEach(({ index, image }) => {
        defaultImages[index - 1] = image;
      });
    };

    // Populate puzzle images based on state
    if (guessPictureStartState?.guessPicturePartDTOList?.length > 0) {
      populatePuzzleImages(guessPictureStartState.guessPicturePartDTOList);
    } else if (guessPictureStart?.guessPicturePartDTOList?.length > 0) {
      populatePuzzleImages(guessPictureStart.guessPicturePartDTOList);
    }

    setPuzzleImagesState(defaultImages);

  }, [guessPictureStartState, guessPictureStart]);

  return (
    <>
      <main className="main-container game-guess-picture">
        <Header />
        <section
          className="games-page page-container wheel-page guess-picture-page"
          id="backgroundPattern"
          style={{ backgroundImage: guessPicture?.backgroundPattern }}
        >
          <div
            className="games-page-introduction"
            style={{ backgroundImage: `url(${BgDots})` }}
            id="backgroundImage"
          >
            <div
              className={
                gameStarted && isFinish === false && guessPictureControl?.remainTime > 0 ? "d-block game-timer" : "d-none game-timer"
              }
            >
              <h3 className="text-center mb-3">Kalan Süre</h3>
              <div className="game-countdown text-center ml-auto mr-auto">
                {guessPicture?.remainTime && guessPictureControl?.remainTime &&
                  <CountdownCircleTimer
                    isPlaying
                    duration={guessPicture?.remainTime}
                    initialRemainingTime={guessPictureControl?.remainTime}
                    colors={timerColors}
                    colorsTime={timerColorsTime}
                    size={80}
                    strokeWidth={8}
                    onComplete={() => handleTimerComplete()}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                }
              </div>

              <div className="text-center">
                <h3 className="pt-2">İpucu Hakkı</h3>
                <p className="text-center">{hint}</p>
              </div>
            </div>

            <Container>
              {isFinish === false && 
                <Row className="justify-content-md-center text-center text-white">  
                  <>
                    {guessPictureControl?.remainTime > 0 ? (
                      <>
                        {gameStarted === false ? (
                          <>
                            {/* Guess Picture Entrance */}
                            <Col xs="12" sm="12" md="8" lg="6" xl="4">
                              <div className="d-flex flex-column justify-content-center align-items-center wrapper-wp-start">
                                <div className="games-page-circle mb-md-5 mb-3">
                                  {guessPicture?.icon && 
                                    <img src={guessPicture?.icon} alt={guessPicture?.title} />
                                  }
                                </div>
                                {guessPicture?.title ? 
                                  <h2 className="mb-3 mb-md-4">{guessPicture?.title}</h2> : <h2 className="mb-3 mb-md-4">Resmi Tahmin Et</h2>
                                }

                                {guessPicture?.description ? 
                                  <p>{guessPicture?.description}</p> : <p>Resmi tahmin etme oyununa hoş geldiniz.</p>
                                }

                                <Button
                                  onClick={() => handleBeginGame()}
                                  className="btn btn-success text-white mt-5 mb-0"
                                >
                                  {guessPicture?.buttonText}
                                </Button>
                              </div>
                            </Col>
                            {/* End Guess Picture Entrance */}
                          </>
                        ) : (
                          <Col>
                            <div className="wrapper-question-start">
                              {/* Puzzle */}
                              <div className="wrapper-puzzle">
                                {puzzleImagesState && puzzleImagesState.length > 0 && puzzleImagesState.map((src, index) => (
                                  <div key={index} className="puzzle-box">
                                    {src && <img src={src} alt={`Puzzle${index + 1}`} />}
                                  </div>
                                ))}
                              </div>
                              {/* End Puzzle */}

                              {/* Question */}
                              <div className="wrapper-question">  
                              <h3>{points} Puan</h3>  
                                <Form onSubmit={(e) => handleFormSubmit(e)}>
                                  <Form.Group as={Row}>
                                    {guessPictureStartState?.questionDescription ? (
                                      <Form.Label
                                        as="legend"
                                        column
                                        sm={12}
                                        style={{ fontSize: "24px", padding: '0', marginBottom: '20px' }}
                                      >
                                        {
                                          guessPictureStartState?.questionDescription
                                        }
                                      </Form.Label>
                                    ) : (
                                      <Form.Label
                                        as="legend"
                                        column
                                        sm={12}
                                        style={{ fontSize: "24px", padding: '0', marginBottom: '20px' }}
                                      >
                                        {
                                          guessPictureStart?.questionDescription
                                        }
                                      </Form.Label>
                                    )}

                                    <Col sm={10} className="mb-3">
                                      {guessPictureStartState?.guessPictureChoiceDTOList?.length > 0 && guessPictureStartState?.guessPictureChoiceDTOList?.map(
                                        (answer, aIndex) => {
                                          return (
                                            <Form.Check
                                              key={aIndex}
                                              type="radio"
                                              label={
                                                answer.description
                                              }
                                              name={"answer"}
                                              value={answer.id}
                                              id={answer.id}
                                              onClick={() => setSelectedAnswer(answer.id)}
                                            />
                                          );
                                        }
                                      )}

                                      {!guessPictureStartState && guessPictureStart?.guessPictureChoiceDTOList?.length > 0 && guessPictureStart?.guessPictureChoiceDTOList?.map(
                                        (answer, aIndex) => {
                                          return (
                                            <Form.Check
                                              key={aIndex}
                                              type="radio"
                                              label={
                                                answer.description
                                              }
                                              name={"answer"}
                                              value={answer.id}
                                              id={answer.id}
                                              onClick={() => setSelectedAnswer(answer.id)}
                                            />
                                          );
                                        }
                                      )}
                                    </Col>
                                  </Form.Group>

                                  <Button
                                    variant="primary"
                                    className="btn btn-info text-white bg-blue"
                                    type="button"
                                    onClick={handleOpenBox}
                                  >
                                    Kutu Aç
                                  </Button>

                                  <Button
                                    variant="primary"
                                    className="btn btn-success mx-2 text-white"
                                    type="submit"
                                    disabled={btnDisabled}
                                  >
                                    Cevapla
                                  </Button>
                                </Form>  
                              </div>
                              {/* End Question && Answers */}
                            </div>
                          </Col>
                        )}
                      </>
                    ) : (
                      <div className="flex flex-column">
                        <div>
                          {/* Guess Picture Entrance */}
                          <div className="d-flex flex-column justify-content-center align-items-center wrapper-wp-start">
                            {guessPicture?.icon ? 
                              <div className="games-page-circle mb-md-5 mb-3">  
                                <img src={guessPicture?.icon} alt={guessPicture?.title} />
                              </div> : 
                              <div className="games-page-circle mb-md-5 mb-3">  
                                <img src={Banner} alt="" />
                              </div>
                            }
                            {guessPicture?.title ? <h2 className="m-0">{guessPicture?.title}</h2> : <h2 className="m-0">Resmi Tahmin Et</h2>}
                          </div>
                          {/* End Guess Picture Entrance */}

                          {/* Guess Picture Manipulated */}
                          {guessPicture ? 
                            <div className="mt-3">Daha önceden yarışmaya katıldınız!<br />Lütfen başka bir oyun gününde tekrar deneyiniz!</div> : 
                            <div className="mt-3">Lütfen Resmi Tahmin Et oyun gününde tekrar deneyiniz!</div>
                          }
                          {/* End Guess Picture Manipulated */}
                        </div>
                      </div>
                    )}
                  </>
                </Row>
              }

              {isFinish === true && 
                <Row className="justify-content-md-center">
                  {isTimeout === true ? 
                    <>
                      <h1>Zamanında Cevaplayamadınız!</h1>
                      {
                        responseMessage && 
                        <p>{responseMessage}</p>
                      } 
                    </> : 
                    <>
                      {isCorrectAnswer === true ? 
                        <Col xs={12} className="text-center find-mate-win active">
                          <h1>Tebrikler Kazandınız!</h1>
                          <p className="!m-0">{responseMessage}</p>
                          
                          <div className="wrapper-reward active">
                            <img src={Girls} alt="" />
                            <h4>{points.toFixed()}</h4>
                          </div>
                        </Col> : <Col xs={12} className="text-center find-mate-win active">
                          <h1>Kaybettiniz!</h1>
                          <p className="!m-0">{responseMessage}</p>

                          <div className="wrapper-reward active">
                            <img src={Girls} alt="" />
                            <h4>0</h4>
                          </div>
                        </Col>
                      }
                    </>
                  }
                  
                </Row>
              }
            </Container>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default GameWordPuzzles;
