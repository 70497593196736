import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import './index.scss';
import App from './App';

import * as serviceWorker from "./serviceWorker";
import { configureStore } from "./redux/store";
import { appContext } from "./config/app.config";

import { ToastContainer } from "react-toastify";

const { store, persistor } = configureStore();

const AppProvider = () => {
    return (
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <RouterProvider basename={appContext}>
                    <App />
                    <ToastContainer autoClose={5000} />
                </RouterProvider>
            </PersistGate>
        </ReduxProvider>
    );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppProvider />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();