/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAction, academyAction } from "../../redux/modules";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Styles
import "./styles.scss";

const AcademyDetail = () => {
  const { topicId } = useParams();
  const dispatch = useDispatch();
  const [filteredAcademy, setFilteredAcademy] = useState(null);

  const academyDetailList = useSelector(
    (store) => store.academy.academyDetailList
  );

  useEffect(() => {
    const reloadControl = localStorage.getItem("reControl");

    if (!reloadControl) {
      localStorage.setItem("reControl", "true");
      dispatch(userAction.setLoginedUser("logined"));
    }

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    // Get Academy Detail List
    dispatch(academyAction.getAcademyDetailList(topicId));

    return () => {};
  }, [dispatch, topicId]);

  useEffect(() => {
    if (academyDetailList && topicId) {


      console.log("academyDetailList.filter((item) => item.subTopicId === topicId): ", academyDetailList.filter((item) => item.subTopicId === topicId))



      if (academyDetailList.filter((item) => item.subTopicId === topicId)) {
        setFilteredAcademy(academyDetailList.filter((item) => item.subTopicId === topicId));
      }
    }
    
    return () => {};
  }, [academyDetailList, topicId]);

  useEffect(() => {
    if (filteredAcademy) {
       console.log("filteredAcademy: ", filteredAcademy);
    }
    
    return () => {};
  }, [filteredAcademy]);

  return (
    <>
      <Header />
      
      <main>
          <section className="bg-blue pt-5 pb-5">
              <div className="container"> 
                  <div className="d-lg-flex d-none text-white small mb-5 opacity-50" data-title-background>
                    <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
                    <span className="me-3">»</span>
                    <NavLink to="/academy" className="text-white text-decoration-none me-3">Akademi</NavLink>
                    <span className="me-3">»</span>
                    {filteredAcademy?.topicCategoryName && 
                      <a href="javascript:void(0)" className="text-white text-decoration-none">
                        {filteredAcademy?.topicCategoryName && filteredAcademy.topicCategoryName}
                      </a>
                    }
                  </div>

                  {filteredAcademy ?
                    <div className="row justify-content-center mb-5" data-title-background>
                        <div className="col-xl-8 col-lg-10 text-center">
                            <h1 className="lh-base mb-4 text-white">{filteredAcademy.name}</h1>
                            <div className="opacity-50 small text-white">
                                <span>{filteredAcademy.dateCreated}</span>
                                <span className="ms-3">#{filteredAcademy.topicSubCategoryName}</span>
                            </div>
                        </div>
                    </div>
                  : (
                    <p>Academy bulunamadı yada yükleniyor...</p>
                  )}
              </div>
          </section>

          <section className="position-relative mb-sm-10 mb-5 mt-n5">
              <div className="container">
                {filteredAcademy ? (
                  <div>
                    <div className="row justify-content-center mb-sm-8 mb-5">
                      <div className="col-xl-10">
                        <div className="overflow-hidden rounded-3">
                          {filteredAcademy?.articleContentDTOList?.length > 0 ? 
                            <img src={filteredAcademy?.articleContentDTOList[0].baseAddress} alt="" className="img-cover w-100" /> :
                            <div className="" style={{ background: '#ddd', height: '390px' }}></div>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-xl-8 col-lg-10 content">
                        <p dangerouslySetInnerHTML={{__html: filteredAcademy.description}}></p>
                      </div>
                    </div>

                  </div>
                ) : (
                  <p>Academy bulunamadı yada yükleniyor...</p>
                )}
              </div>
          </section>
      </main>
      
      <Footer />
    </>
  );
};

export default AcademyDetail;
