import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesAction } from "../../redux/modules";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

// Styles
import "./styles.scss";

// Images
import BgDots from "../../assets/images/games/bg-pattern.png";
import Girls from "../../assets/images/games/lucky-wheel/girls.png";

// CountdownCircleTimer
import { CountdownCircleTimer } from "react-countdown-circle-timer";

// Toastify
import showMessage from "../../components/ShowMessage";
import showErrMessage from "../../components/ShowErrMessage";

const GameWordPuzzles = () => {
  const dispatch = useDispatch();
  
  // Game Hangman
  const hangman = useSelector((store) => store.games.hangman);
  
  // Game Hangman Control
  const hangmanControl = useSelector((store) => store.games.hangmanControl);

  // Letter
  const [letter, setLetter] = useState("");

  // Start Game
  const [gameStarted, setGameStarted] = useState(false);

  // WordList
  const [wordList, setWordList] = useState([]);

  // Game is Finish? 
  const [isFinish, setIsFinish] = useState(false);

  // Response Message
  const [responseMessage, setResponseMessage] = useState("");
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  // Timer Defaults
  const [timerColors] = useState(["#76e17b", "#F7B801"]);
  const [timerColorsTime, setTimerColorsTime] = useState([90, 0]);
  const [timerDuration, setTimerDuration] = useState(0);
  
  // Right of Answer
  const [numberOfTry, setNumberOfTry] = useState(0);
  const [numberOfLetterTry, setNumberOfLetterTry] = useState(0);

  // Keyboard
  const [keyboard1] = useState(["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "Ğ", "Ü"]);
  const [keyboard2] = useState(["A", "S", "D", "F", "G", "H", "J", "K", "L", "Ş", "İ"]);
  const [keyboard3] = useState(["Z", "X", "C", "V", "B", "N", "M", "Ö", "Ç"]);

  // CountdownTimer When Complete
  const handleTimerComplete = () => {
    setIsFinish(true);
    
    // dispatch(gamesAction.getHangman());
    // dispatch(gamesAction.getHangmanControl());
  };

  // handleShowLetters
  const handleBeginGame = () => {    
    if (hangman) {
      // Start Hangman
      dispatch(gamesAction.hangmanStart()).then((res) => {
        if (res.isHttpSuccess) {
          setGameStarted(true);

          // Check Letters - for example: BCDYZİ
          if (hangmanControl?.guessLetterDTOS?.length > 0) {
            const contentList = [...wordList];
          
            for (let j = 0; j <= hangmanControl?.guessLetterDTOS.length; j++) {
              for (let item = 0; item <= contentList.length; item++) {
                if (item === hangmanControl?.guessLetterDTOS[j]?.word) {
                  contentList[item][hangmanControl?.guessLetterDTOS[j].index] = hangmanControl?.guessLetterDTOS[j].letter;
                }
              }
            }
          
            setWordList(contentList);
          } else {
            setTimeout(() => {
              dispatch(gamesAction.hangmanCheckLetters(hangman?.id, hangman?.guessLetterList)).then((response) => {
                // Success
                if (response.isHttpSuccess) {
                  const guessList = response.data;
                  const contentList = [...wordList];
    
                  for (let j = 0; j <= guessList.length; j++) {
                    for (let item = 0; item <= contentList.length; item++) {
                      if (item === guessList[j]?.word) {
                        contentList[item][guessList[j].index] = guessList[j].letter;
                      }
                    }
                  }
                
                  setWordList(contentList);
                }
              });
            }, 1000);
          }
        }
      }).catch((err) => {
        // Error
        if (err.isHttpError) {
          console.log(err.isHttpError);
        }
      });
    }
  }

  const handleSaveAnswer = (e) => {
    e.preventDefault();
    if (letter.length > 0) {
      // hangmanCheckLetters 
      dispatch(gamesAction.hangmanCheckAnswer(hangman.id, letter)).then((response) => {
        // Success
        if (response.isHttpSuccess) {
          setResponseMessage(response.data.responseText);

          if (response.data.answerTrue === true) {
            setIsCorrectAnswer(true);
            setIsFinish(true);
          } else if (response.data.answerFalse === true) {
            if (numberOfTry > 1) {
              setIsCorrectAnswer(false);
              setIsFinish(false);
              showErrMessage(response.data.responseText);
            } else {
              setIsCorrectAnswer(false);
              setIsFinish(true);
            }

          } else if (response.data.answerTimeOut === true) {
            setIsTimeout(true);
            setIsFinish(true);
          }

          setLetter('');
          setNumberOfTry(numberOfTry - 1);
        }
      })
      .catch((err) => {
        // Error
        if (err.isHttpError) {
          console.log(err.isHttpError);
          setLetter('');
        }
      });
    } else {
      showErrMessage("Lütfen geçerli bir tahminde bulunuz!")
    }
  }

  const handleClickKey = (key) => {
    if (key) {
      // API numberOfLetterTry: 4 düşürülmeli her bir denemede
      if (hangman?.id && numberOfLetterTry > 0) {
        // hangmanCheckOneLetter
        dispatch(gamesAction.hangmanCheckOneLetter(hangman.id, key)).then((response) => {
          // Success
          if (response.isHttpSuccess) {
            const guessList = response.data;
            const contentList = [...wordList];

            for (let j = 0; j <= guessList.length; j++) {
              for (let item = 0; item <= contentList.length; item++) {
                if (item === guessList[j]?.word) {
                  contentList[item][guessList[j].index] = guessList[j].letter;
                }
              }
            }

            setWordList(contentList);
            setLetter('');

            if (response?.data[0]?.answerTrue === true) {
              setIsFinish(true);
              setIsCorrectAnswer(true);
              setResponseMessage(response?.data[0]?.responseText || "");
            } else {
              // get control
              dispatch(gamesAction.getHangmanControl());
            }
          }
        })
        .catch((err) => {
          // Error
          if (err.isHttpError) {
            console.log(err.isHttpError);
            setLetter('');
          }
        });

        setNumberOfLetterTry(numberOfLetterTry - 1);
      }
    }
  }

  useEffect(() => {
    dispatch(gamesAction.getHangman());
    dispatch(gamesAction.getHangmanControl());

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (hangman) {
      let content = [];
      for (let item of hangman?.wordList) {
        let letterList = [];
        if (item > 0) {
          for (let x = 1; x <= item; x++) {
            letterList.push( "" );
          }
        }
        content.push(letterList);
      }
      setWordList(content);
    }
  }, [hangman]);

  // Defaults
  useEffect(() => {
    if (hangmanControl) {
      console.log("hangmanControl1: ", hangmanControl);

      // Numbers
      setNumberOfTry(hangmanControl.numberOfTry);
      setNumberOfLetterTry(hangmanControl.numberOfLetterTry);
      
      // Timer
      setTimerDuration(hangmanControl.remainTime);

      // Timer Colors
      setTimerColorsTime([hangmanControl.remainTime, 0]);
      setGameStarted(hangmanControl.gameIsOn);

      if (hangmanControl.gameIsOn === true && hangmanControl.remainTime > 0) {
        handleBeginGame();
        console.log("handleBeginGame calisti...")
      }
    }
  }, [hangmanControl]);

  return (
    <>
      <main className="main-container game-word-puzzles">
        <Header />
        <section
          className="games-page page-container wheel-page word-puzzles-page"
          id="backgroundPattern"
          style={{ backgroundImage: hangman?.backgroundPattern }}
        >
          <div
            className="games-page-introduction"
            style={{ backgroundImage: `url(${BgDots})` }}
            id="backgroundImage"
          >

            <div
              className={
                gameStarted && isFinish === false ? "d-block game-timer" : "d-none game-timer"
              }
            >
              <h3 className="text-center">Kalan Süre</h3>
              <div className="game-countdown text-center ml-auto mr-auto">
                <CountdownCircleTimer
                  isPlaying
                  duration={timerDuration}
                  colors={timerColors}
                  colorsTime={timerColorsTime}
                  size={80}
                  strokeWidth={8}
                  onComplete={() => handleTimerComplete()}
                >
                  {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
              </div>

              <div className="text-center">
                <h3 className="pt-2">Cevap Hakkı</h3>
                <p className="text-center">{numberOfTry}</p>
              </div>

              <div className="text-center">
                <h3 className="pt-2">Harf İste</h3>
                <p className="text-center">{numberOfLetterTry}</p>
              </div>
            </div>

            <Container>
              {isFinish === false && 
                <Row className="justify-content-md-center text-center text-white">
                  {gameStarted === false ? (
                    <Col xs="12" sm="12" md="8" lg="6" xl="4">
                      <div className="d-flex flex-column justify-content-center align-items-center wrapper-wp-start">
                        <div className="games-page-circle mb-md-5 mb-3">
                          <img src={hangman?.icon} alt={hangman?.title} />
                        </div>
                        <h2 className="mb-3 mb-md-4">{hangman?.title}</h2>
                        <p>{hangman?.description}</p>
                        {numberOfTry ? (
                          <Button
                            onClick={() => handleBeginGame()}
                            className="btn btn-success mt-5 mb-0 text-white"
                          >
                            {hangman?.buttonText}
                          </Button>
                        ) : (
                          <div className="mt-3">Daha önceden yarışmaya katıldınız. Lütfen daha sonra tekrar deneyiniz.</div>
                        )}
                      </div>
                    </Col>
                  ) : (
                    <Col>
                      <div className="hangman-competition">

                        <div className="hangman-information">
                          <h2 className="mb-3 mb-md-4">{hangman?.title}</h2>
                          <p>{hangman?.inGameDescription}</p>
                        </div>

                        <div className="hangman-letters">
                          {wordList && wordList.length > 0 && (
                            wordList.map((item, index) => (
                              <div className="hangman-letters-items" id={`hangman-${index}`} key={index}>
                                {Array.isArray(item) && (
                                  item.map((it, index2) => (
                                    <input
                                      key={index2}
                                      type="text"
                                      name="text"
                                      value={it !== null && it !== undefined ? it : ""}
                                      maxLength={1}
                                      disabled={true}
                                    />
                                  ))
                                )}
                              </div>
                            ))
                          )}
                        </div>

                        <div className="hangman-guess-letters">
                          <h5>Size Verilen Harfler</h5>
                          <ul className="!mt-3 mb-0">
                            {hangman && hangman.guessLetterList?.length > 0 && (
                              hangman.guessLetterList.map((item) => <li key={item}>{item}</li>)
                            )}
                          </ul>
                        </div>

                        <div className="hangman-answer m-auto">
                          <Form onSubmit={(e) => e.preventDefault(e)}>
                            {numberOfLetterTry > 0 && 
                              <div className="keyboard">
                                <div className="keyboard-line">
                                  {keyboard1 &&
                                    keyboard1.map(res => {
                                      const guessLetterDTOsList = hangmanControl?.guessLetterDTOS ? hangmanControl.guessLetterDTOS.map(item => item.letter) : [];
                                      const isDisabled = guessLetterDTOsList.includes(res) || hangman.guessLetterList.includes(res);
                                      return (
                                        <button 
                                          key={res}
                                          disabled={isDisabled} 
                                          className="key" 
                                          onClick={() => handleClickKey(res)}
                                        >
                                          <span>{res}</span>
                                        </button>
                                      );
                                    })
                                  }
                                </div>

                                <div className="keyboard-line">
                                  {keyboard2 &&
                                    keyboard2.map(res => {
                                      const guessLetterDTOsList = hangmanControl?.guessLetterDTOS ? hangmanControl.guessLetterDTOS.map(item => item.letter) : [];
                                      const isDisabled = guessLetterDTOsList.includes(res) || hangman.guessLetterList.includes(res);
                                      return (
                                        <button 
                                          key={res}
                                          disabled={isDisabled} 
                                          className="key" 
                                          onClick={() => handleClickKey(res)}
                                        >
                                          <span>{res}</span>
                                        </button>
                                      );
                                    })
                                  }
                                </div>

                                <div className="keyboard-line">
                                  {keyboard3 &&
                                    keyboard3.map(res => {
                                      const guessLetterDTOsList = hangmanControl?.guessLetterDTOS ? hangmanControl.guessLetterDTOS.map(item => item.letter) : [];
                                      const isDisabled = guessLetterDTOsList.includes(res) || hangman.guessLetterList.includes(res);
                                      return (
                                        <button 
                                          key={res}
                                          disabled={isDisabled} 
                                          className="key" 
                                          onClick={() => handleClickKey(res)}
                                        >
                                          <span>{res}</span>
                                        </button>
                                      );
                                    })
                                  }
                                </div>
                              </div>
                            }

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Control type="text" className="guessLetter" value={letter || ""} onChange={(e) => setLetter(e.target.value)} />
                              <Button className="btn btn-success" type="button" onClick={(e) => handleSaveAnswer(e)}>
                                Tahminde Bulun
                              </Button>
                            </Form.Group>

                          </Form>
                        </div>
                        
                      </div>
                    </Col>
                  )}
                </Row>
              }

              {isFinish === true && 
                <Row className="justify-content-md-center">
                  {isTimeout === true ? 
                    <>
                      <h1>Zamanında Cevaplayamadınız!</h1>
                      {
                        responseMessage && 
                        <p>{responseMessage}</p>
                      } 
                    </> : 
                    <>
                      {isCorrectAnswer === true ? 
                        <Col xs={12} className="text-center find-mate-win active">
                          <h1>Tebrikler Kazandınız!</h1>
                          <p className="!m-0">{responseMessage}</p>
                          
                          <div className="wrapper-reward active">
                            <img src={Girls} alt="" />
                            <h4>{hangman?.score}</h4>
                          </div>
                        </Col> : 
                        <Col xs={12} className="text-center find-mate-win active">
                          <h1>Kaybettiniz!</h1>
                          <p className="!m-0">{responseMessage}</p>
                          
                          <div className="wrapper-reward active">
                            <img src={Girls} alt="" />
                            <h4>0</h4>
                          </div>
                        </Col>
                      }
                    </>
                  }
                  
                </Row>
              }
            </Container>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default GameWordPuzzles;
