/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authAction } from "../../../redux/modules";

//style
import style from "./signUp.module.scss";

// Bootstrap
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// Toastify
import showErrMessage from "../../../components/ShowErrMessage";
import showMessage from "../../../components/ShowMessage";

// Timer
import Timer from "../../../components/Timer";

// History
import { useHistory } from "react-router-dom";

// Form Validations
import { Formik } from "formik";
import * as yup from "yup";

// Images
import Logo from "../../../assets/images/logo.png";

const SingUp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [confirmValidated, setConfirmValidated] = useState(false);
  const [sentCode, setSentCode] = useState(false);
  const [time, setTime] = useState(null);
  const [timerExpired, setTimerExpired] = useState(false);
  const [confirmMobileNo, setConfirmMobileNo] = useState("");
  const [confirmUsername, setConfirmUsername] = useState("");
  const [confirmCode, setConfirmCode] = useState("");

  const schema = yup.object({
    name: yup.string().required("Lütfen Adınızı Giriniz!"),
    surname: yup.string().required("Lütfen Soyadınızı Giriniz!"),
    mobileNo: yup.string().required("Lütfen Telefon Numaranızı Giriniz!"),
    email: yup
      .string()
      .email("Lütfen Gerçek Bir E-Posta Adresi Giriniz!")
      .required("Lütfen E-Posta Adresi Giriniz!"),
    username: yup.string().required("Lütfen Kullanıcı Adı Giriniz!"),
    vendorName: yup.string().required("Bayi Table Adınızı Giriniz!"),
    salesDocumentNumber: yup
      .string()
      .required("Lütfen Satış Belgesi Numaranızı Giriniz!"),
    salesDocumentType: yup
      .string()
      .required("Lütfen Doküman Tipinizi Seçiniz!"),
    password: yup.string().required("Lütfen Şifrenizi Giriniz!").matches(
      //"/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/",
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.,!])(?=\\S+$).{8,}$",
      "Şifreniz 8 Karakter, Bir Büyük Harf, Bir Küçük Harf, Bir Rakam ve Bir Özel Harf Karakteri İçermelidir!"
    ),
    passwordAgain: yup
      .string()
      .required("Lütfen Şifre Tekrarı Giriniz!")
      .oneOf([yup.ref("password"), null], "Şifreler Eşleşmiyor!"),
    agreement: yup
      .bool()
      .required()
      .oneOf([true], "Sözleşmeleri kabul etmek zorundasınız!"),
  });

  const handleConfirmCode = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity() !== false) {
      dispatch(
        authAction.registerVendorConfirm(
          confirmCode,
          confirmMobileNo,
          confirmUsername
        )
      )
        .then((e) => {
          if (e.isHttpSuccess) {
            showMessage("Kayıt Başarıyla Oluşturuldu.");
            setSentCode(true);
            history.push("/login");
          }
        })
        .catch((err) => {
          if (err.isHttpError) {
            showErrMessage(err.message);
          }
        });
    }
    setConfirmValidated(true);
  };

  const handleSubmitSignupForm = (values) => {
    // Dispatch the registration action
    dispatch(authAction.registerVendor(values))
      .then((e) => {
        if (e.isHttpSuccess) {
          // For a Confirmation
          setConfirmMobileNo(values.mobileNo);
          setConfirmUsername(values.username);

          const time = new Date();
          time.setSeconds(time.getSeconds() + 120);
          setTime(time);
          setSentCode(true);
        }
      })
      .catch((err) => {
        if (err.isHttpError) {
          showErrMessage(err.message);
          if (err.error.response.data.code === 1044) {
            setTimeout(() => {
              history.push("/login");
            }, 2500);
          }
        }
      });
  };

  useEffect(() => {
    if (timerExpired === true) {
      showErrMessage(
        "Süreniz doldu! Lütfen, Tekrar Kod Gönder butonuna basarak kod isteyiniz!"
      );
    }

    return () => {};
  }, [timerExpired]);

  const handleExpire = (expired) => {
    setTimerExpired(expired);
    setTimeout(() => {
      history.push("/login");
    }, 2000);
  };

  return (
    <React.Fragment>
      <section className="bg-gray-lightest min-vh-100">
        <Formik
          validationSchema={schema}
          onSubmit={(values) => handleSubmitSignupForm(values)}
          initialValues={{
            name: "",
            surname: "",
            mobileNo: "",
            email: "",
            username: "",
            vendorName: "",
            salesDocumentNumber: "",
            salesDocumentType: "",
            password: "",
            passwordAgain: "",
            agreement: false,
            tenantName: "tenant1",
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              className={sentCode ? "d-none" : "d-flex"}
            >
              <div className="container">
                <div className="row justify-content-center pt-5">
                  <div className="col-xl-4 col-lg-6 col-md-8 col-10 w-100">
                    <div className="text-center mb-3">
                      <img src={Logo} alt="" className="auth-logo" />
                    </div>
                    <h5 className="lh-base text-center fw-normal mb-3">
                      Lütfen üye olmak için gerekli alanları doldurunuz.
                    </h5>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-10 mb-3">
                    <div className="form-floating shadow-lg rounded-3">
                      <Form.Group controlId="validationCustom01">
                        <Form.Control
                          type="text"
                          className="form-control border-0 rounded-3 px-4"
                          placeholder="Üye Adı"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="name"
                          value={values.name}
                          isInvalid={
                            !!errors.name && touched.name && !!errors.name
                          }
                        />
                      </Form.Group>
                    </div>
                    <span className={style.errorMessage}>
                      {errors.name && touched.name && errors.name}
                    </span>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-10 mb-3">
                    <div className="form-floating shadow-lg rounded-3">
                      <Form.Group controlId="validationCustom02">
                        <Form.Control
                          className="form-control border-0 rounded-3 px-4"
                          type="text"
                          placeholder="Üye Soyadı"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="surname"
                          value={values.surname}
                          isInvalid={
                            !!errors.surname &&
                            touched.surname &&
                            !!errors.surname
                          }
                        />
                      </Form.Group>
                    </div>
                    <span className={style.errorMessage}>
                      {errors.surname && touched.surname && errors.surname}
                    </span>
                  </div>
                  <div className="w-100"></div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-10 mb-3">
                    <div className="form-floating shadow-lg rounded-3">
                      <Form.Group controlId="validationCustom03">
                        <Form.Control
                          type="text"
                          className="form-control border-0 rounded-3 px-4"
                          placeholder="05(__) ___ __ __"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="mobileNo"
                          value={values.mobileNo}
                          maxLength="11"
                          isInvalid={
                            !!errors.mobileNo &&
                            touched.mobileNo &&
                            !!errors.mobileNo
                          }
                        />
                      </Form.Group>
                    </div>
                    <span className={style.errorMessage}>
                      {errors.mobileNo && touched.mobileNo && errors.mobileNo}
                    </span>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-10 mb-3">
                    <div className="form-floating shadow-lg rounded-3">
                      <Form.Group controlId="validationCustom04">
                        <Form.Control
                          type="email"
                          className="form-control border-0 rounded-3 px-4"
                          placeholder="Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="email"
                          value={values.email}
                          isInvalid={
                            !!errors.email && touched.email && !!errors.email
                          }
                        />
                      </Form.Group>
                    </div>
                    <span className={style.errorMessage}>
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>
                  <div className="w-100"></div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-10 mb-3">
                    <div className="form-floating shadow-lg rounded-3">
                      <Form.Group controlId="validationCustom05">
                        <Form.Control
                          className="form-control border-0 rounded-3 px-4"
                          autoComplete="chrome-off"
                          type="text"
                          placeholder="Kullanıcı Adı"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="username"
                          value={values.username}
                          isInvalid={
                            !!errors.username &&
                            touched.username &&
                            !!errors.username
                          }
                        />
                      </Form.Group>
                    </div>
                    <span className={style.errorMessage}>
                      {errors.username && touched.username && errors.username}
                    </span>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-10 mb-3">
                    <div className="form-floating shadow-lg rounded-3">
                      <Form.Group controlId="validationCustom06">
                        <Form.Control
                          autoComplete="chrome-off"
                          className="form-control border-0 rounded-3 px-4"
                          type="text"
                          placeholder="Bayi Tabela Adı"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="vendorName"
                          value={values.vendorName}
                          isInvalid={
                            !!errors.vendorName &&
                            touched.vendorName &&
                            !!errors.vendorName
                          }
                        />
                      </Form.Group>
                    </div>
                    <span className={style.errorMessage}>
                      {errors.vendorName &&
                        touched.vendorName &&
                        errors.vendorName}
                    </span>
                  </div>
                  <div className="w-100"></div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-10 mb-3">
                    <div className="form-floating shadow-lg rounded-3">
                      <Form.Group controlId="validationCustom07">
                        <Form.Control
                          autoComplete="chrome-off"
                          className="form-control border-0 rounded-3 px-4"
                          type="text"
                          placeholder="Satış Belgesi Numarası"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="salesDocumentNumber"
                          value={values.salesDocumentNumber}
                          isInvalid={
                            !!errors.salesDocumentNumber &&
                            touched.salesDocumentNumber &&
                            !!errors.salesDocumentNumber
                          }
                        />
                      </Form.Group>
                    </div>
                    <span className={style.errorMessage}>
                      {errors.salesDocumentNumber &&
                        touched.salesDocumentNumber &&
                        errors.salesDocumentNumber}
                    </span>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-10 mb-3">
                    <div className="form-floating shadow-lg rounded-3">
                      <Form.Group controlId="validationCustom08">
                        <Form.Control
                          as="select"
                          className="form-select border-0 rounded-3 px-4"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="salesDocumentType"
                          value={values.salesDocumentType}
                          isInvalid={
                            !!errors.salesDocumentType &&
                            touched.salesDocumentType &&
                            !!errors.salesDocumentType
                          }
                        >
                          <option value="0">Seçiniz</option>
                          <option value="P">P</option>
                          <option value="PTT">PT</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                    <span className={style.errorMessage}>
                      {errors.salesDocumentType &&
                        touched.salesDocumentType &&
                        errors.salesDocumentType}
                    </span>
                  </div>
                  <div className="w-100"></div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-10 mb-3">
                    <div className="form-floating shadow-lg rounded-3">
                      <Form.Group controlId="validationCustom09">
                        <Form.Control
                          autoComplete="chrome-off"
                          className="form-control border-0 rounded-3 px-4"
                          type="password"
                          placeholder="Şifre"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="password"
                          value={values.password}
                          isInvalid={
                            !!errors.password &&
                            touched.password &&
                            !!errors.password
                          }
                        />
                      </Form.Group>
                    </div>
                    <span className={style.errorMessage}>
                      {errors.password && touched.password && errors.password}
                    </span>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-10 mb-3">
                    <div className="form-floating shadow-lg rounded-3">
                      <Form.Group controlId="validationCustom10">
                        <Form.Control
                          autoComplete="chrome-off"
                          className="form-control border-0 rounded-3 px-4"
                          type="password"
                          placeholder="Şifre Tekrar"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="passwordAgain"
                          value={values.passwordAgain}
                          isInvalid={
                            !!errors.passwordAgain &&
                            touched.passwordAgain &&
                            !!errors.passwordAgain
                          }
                        />
                      </Form.Group>
                    </div>
                    <span className={style.errorMessage}>
                      {errors.passwordAgain &&
                        touched.passwordAgain &&
                        errors.passwordAgain}
                    </span>
                  </div>
                  <div className="w-100"></div>

                  <div className="col-xl-6 col-lg-8 col-md-12 col-10">
                    <div className="form-check">
                      <Form.Group>
                        <Form.Label></Form.Label>
                        <Form.Check
                          className={style.formCheckbox}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="agreement"
                          value={values.agreement}
                          label="Kişisel Veriler Hakkında Bildirim ve Aydınlatma Metni'ni okudum, anladım ve kabul ediyorum."
                          isInvalid={
                            !!errors.agreement &&
                            touched.agreement &&
                            !!errors.agreement
                          }
                          id="agreement"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="w-100"></div>

                  <div className="col-xl-6 col-lg-8 col-md-12 col-10">
                    <button
                      type="submit"
                      className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 rounded-3 w-100 mb-3 mt-2"
                    >
                      Üye Ol<i className="fas fa-arrow-right ms-3"></i>
                    </button>
                    <div className="text-center text-gray small">
                      <Link to="/login">Giriş Yap</Link> veya{" "}
                      <Link to="/passwordReset">Şifremi Unuttum</Link>
                    </div>
                  </div>
                  <div className="w-100"></div>

                  <div className="col-xl-4 col-lg-6 col-md-8 col-10">
                    {/* <LoginFooter /> */}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className="col-xl-12">
          {/* TODO: Timer Component */}
          <Form
            noValidate
            validated={confirmValidated}
            onSubmit={handleConfirmCode}
            className={sentCode ? "d-flex flex-column" : "d-none"}
          >
            {time && (
              <div className="mb-5 ml-auto mr-auto">
                <Timer expiryTimestamp={time} onExpire={handleExpire} />
              </div>
            )}

            <Form.Group
              controlId="validationCustom01"
              style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Form.Control
                type="text"
                placeholder="Onay Kodu"
                value={confirmCode}
                onChange={(e) => setConfirmCode(e.target.value)}
                disabled={timerExpired ? true : false}
              />
            </Form.Group>

            <div
              style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                block
                variant="success"
                type="submit"
                disabled={timerExpired ? true : false}
              >
                Onay Kodu Gönder
              </Button>
            </div>
          </Form>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SingUp;
