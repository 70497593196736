export const GET_PRODUCT_BY_ID_PENDING = "GET_PRODUCT_BY_ID_PENDING";
export const GET_PRODUCT_BY_ID_FULFILLED = "GET_PRODUCT_BY_ID_FULFILLED";
export const GET_PRODUCT_BY_ID_REJECTED = "GET_PRODUCT_BY_ID_REJECTED";

export const GET_PRODUCT_RATE_BY_ID_PENDING = "GET_PRODUCT_RATE_BY_ID_PENDING";
export const GET_PRODUCT_RATE_BY_ID_FULFILLED =
  "GET_PRODUCT_RATE_BY_ID_FULFILLED";
export const GET_PRODUCT_RATE_BY_ID_REJECTED =
  "GET_PRODUCT_RATE_BY_ID_REJECTED";

export const GET_PRODUCT_FAVOURITES_PENDING = "GET_PRODUCT_FAVOURITES_PENDING";
export const GET_PRODUCT_FAVOURITES_FULFILLED = "GET_PRODUCT_FAVOURITES_FULFILLED";
export const GET_PRODUCT_FAVOURITES_REJECTED = "GET_PRODUCT_FAVOURITES_REJECTED";

export const SET_FAVOURITE_PRODUCT_BY_ID_PENDING =
  "SET_FAVOURITE_PRODUCT_BY_ID_PENDING";
export const SET_FAVOURITE_PRODUCT_BY_ID_FULFILLED =
  "SET_FAVOURITE_PRODUCT_BY_ID_FULFILLED";
export const SET_FAVOURITE_PRODUCT_BY_ID_REJECTED =
  "SET_FAVOURITE_PRODUCT_BY_ID_REJECTED";

export const REMOVE_FAVOURITE_PRODUCT_BY_ID_PENDING =
  "REMOVE_FAVOURITE_PRODUCT_BY_ID_PENDING";
export const REMOVE_FAVOURITE_PRODUCT_BY_ID_FULFILLED =
  "REMOVE_FAVOURITE_PRODUCT_BY_ID_FULFILLED";
export const REMOVE_FAVOURITE_PRODUCT_BY_ID_REJECTED =
  "REMOVE_FAVOURITE_PRODUCT_BY_ID_REJECTED";
