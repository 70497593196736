import * as academyTypes from './academy.type';

const initialState = {
  academyList: [],
  academyCategoryList: [],
  academyDetailList: [],
  error: null,
  isLoading: false,
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${academyTypes.GET_ACADEMY_LIST_PENDING}`:
    case `${academyTypes.POST_ACADEMY_CATEGORY_FILTER_PENDING}`:
    case `${academyTypes.GET_ACADEMY_CATEGORY_LIST_PENDING}`:
    case `${academyTypes.GET_ACADEMY_DETAIL_LIST_PENDING}`:
      return { ...state, isLoading: true, error: null };

    // Academy List
    case `${academyTypes.GET_ACADEMY_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        academyList: payload.data,
      };
    case `${academyTypes.GET_ACADEMY_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        academyList: [],
      };

    // Academy List Filter By Id
    case `${academyTypes.POST_ACADEMY_CATEGORY_FILTER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        academyList: payload.data.videoTopicDTOList,
      };
    case `${academyTypes.POST_ACADEMY_CATEGORY_FILTER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        academyList: [],
      };

    // Academy Category List
    case `${academyTypes.GET_ACADEMY_CATEGORY_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        academyCategoryList: payload.data,
      };
    case `${academyTypes.GET_ACADEMY_CATEGORY_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        academyCategoryList: [],
      };

    // Academy Detail List
    case `${academyTypes.GET_ACADEMY_DETAIL_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        academyDetailList: payload.data,
      };
    case `${academyTypes.GET_ACADEMY_DETAIL_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        academyDetailList: [],
      };

    default:
      return state;
  }
};

export default reducer;
