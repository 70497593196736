import * as forumTypes from './forum.type';

const initialState = {
  forumAllTopics: [],
  forumWeeklyTopic: null,
  forumComments: [],
  error: null,
  isLoading: false,
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${forumTypes.GET_FORUM_ALL_TOPICS_PENDING}`:
    case `${forumTypes.GET_FORUM_WEEKLY_TOPIC_PENDING}`:
    case `${forumTypes.GET_FORUM_COMMENTS_PENDING}`:
    case `${forumTypes.SET_FORUM_COMMENT_BY_ID_PENDING}`:
      return { ...state, isLoading: true, error: null };

    // Get Forum All Topics
    case `${forumTypes.GET_FORUM_ALL_TOPICS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        forumAllTopics: payload.data,
      };
    case `${forumTypes.GET_FORUM_ALL_TOPICS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        forumAllTopics: [],
      };

    // Get Forum Weekly Topic
    case `${forumTypes.GET_FORUM_WEEKLY_TOPIC_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        forumWeeklyTopic: payload.data,
      };
    case `${forumTypes.GET_FORUM_WEEKLY_TOPIC_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        forumWeeklyTopic: [],
      };

    // Get Forum Comments
    case `${forumTypes.GET_FORUM_COMMENTS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        forumComments: payload.data,
      };
    case `${forumTypes.GET_FORUM_COMMENTS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        forumComments: [],
      };

    // Set Forum Comment By Id
    case `${forumTypes.SET_FORUM_COMMENT_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };
    case `${forumTypes.SET_FORUM_COMMENT_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default reducer;
