/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { userAction, gamesAction, brandAction } from "../../redux/modules";

// Header Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import TitleBackground from "../../components/TitleBackground";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import './styles.scss';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faHeart } from "@fortawesome/free-solid-svg-icons";

import ScoreHuntBanner from "../../assets/images/games/score-hunt/score-hunt-banner.png";

const BrandProductDetail = () => {
  const dispatch = useDispatch();
  const {brandSubCategoryId, brandStyleId} = useParams();

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // Store
  const brandStyleActive = useSelector((store) => store.brand.brandStyleActive);
  const brandStyleList = useSelector((store) => store.brand.brandStyleList);
  // Game Score Hunt
  const scoreHunt = useSelector((store) => store.games.scoreHunt);

  // Game States
  const [showGame, setShowGame] = useState(false);
  const [availableGame, setAvailableGame] = useState(false);

   // Active BrandStyle
   const [activeBrandStyle, setActiveBrandStyle] = useState(null);

  useEffect(() => {
    if (brandSubCategoryId) {
      dispatch(brandAction.getBrandSubCategoryStyleList(brandSubCategoryId));
    }
    return () => {};
  }, [brandSubCategoryId, dispatch]);

  useEffect(() => {
    // Get All Brand Style List
    dispatch(brandAction.getBrandStyleList(0, 5));

    // Score Hunt
    dispatch(gamesAction.scoreHunt());

    // Score Hunt Control
    dispatch(gamesAction.scoreHuntControl()).then((res) => {
      if (res.isHttpSuccess) {
        if (res.data.remainTime > 0) {
          setAvailableGame(true);
        }
      }
    });

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    
    console.log("scoreHunt?.brandStyleId: ", scoreHunt?.brandStyleId);
    console.log("brandStyleId: ", brandStyleId);

    if ( parseInt(scoreHunt?.brandStyleId) === parseInt(brandStyleId) ) {
      setShowGame(true);
    }
  }, [scoreHunt, brandStyleId]);

  useEffect(() => {
    if(showGame) {
      console.log("showGame: ", showGame);
    }

  }, [showGame]);

  useEffect(() => {
    if (brandStyleId && brandStyleActive) {

      console.log("brandStyleId:" , brandStyleId);

      const selectedBrandStyle = brandStyleActive?.content?.find(style => style.id === parseInt(brandStyleId));
      setActiveBrandStyle(selectedBrandStyle);
    }

    return () => {};
  }, [brandStyleId, brandStyleActive, dispatch]);

  useEffect(() => {
    const reloadControl = localStorage.getItem("reControl");

    if (!reloadControl) {
      localStorage.setItem("reControl", "true");
      dispatch(userAction.setLoginedUser("logined"));
    }
    return () => {};
  }, []);

  const handleAddFavourite = (e, activeBrandStyleId) => {
    e.preventDefault();

    if (activeBrandStyleId) {
      dispatch(brandAction.setFavouriteBrandStyle(activeBrandStyleId)).then(res => {
        if (res.isHttpSuccess) {
          dispatch(brandAction.getBrandSubCategoryStyleList(brandSubCategoryId));
        }
      });
    }
  }

  const handleRemoveFavourite = (e, activeBrandStyleId) => {
    e.preventDefault();

    if (activeBrandStyleId) {
      dispatch(brandAction.deleteFavouriteBrandStyle(activeBrandStyleId)).then(res => {
        if (res.isHttpSuccess) {
          dispatch(brandAction.getBrandSubCategoryStyleList(brandSubCategoryId));
        }
      });
    }
  }

  return (
    <>
      <Header />

      <main>
        <section className="position-relative pt-sm-5 pt-3 mb-sm-10 mb-5" data-title-background="pt">
          <TitleBackground />
          <div className="container">
            <div className="d-lg-flex d-none text-white small mb-4 opacity-50" data-title-background>
              <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
              <span className="me-3">»</span>
              <NavLink to="/brands" className="text-white text-decoration-none me-3">Ürünlerimiz</NavLink>
              <span className="me-3">»</span>
              {activeBrandStyle?.brandSubCategoryName && 
                <span>{activeBrandStyle?.brandSubCategoryName}</span>
              }
            </div>
            
            <div className="row gy-5">
              <div className="col-lg-5">
                
                {/* Main Slider */}
                <div className="position-relative bg-white rounded-3 overflow-hidden mb-2">
                  <Swiper
                    spaceBetween={0}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="swiper-product-slider rounded-3"
                  >
                    {activeBrandStyle?.brandStyleContentDTOList?.length > 0 ? activeBrandStyle?.brandStyleContentDTOList?.map(item => {  
                      return (
                        <SwiperSlide key={item.id}>
                          <a href={item.baseAddress} className="d-block" data-fancybox>
                            <img src={item.baseAddress} alt={activeBrandStyle?.name} className="w-100 img-cover" />
                          </a>
                        </SwiperSlide>
                      )
                    }) : 
                      <SwiperSlide>
                        <div style={{ background: "#000", width: '100%' }}>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/img/logo.png"
                            }
                            alt=""
                            className="w-100 opacity-50"
                            style={{
                              width: "310px",
                              height: "100%",
                              objectFit: "scale-down",
                            }}
                          />
                        </div>
                      </SwiperSlide>
                    }
                  </Swiper>
                  {/* End Main Slider */}

                  {/* Thumnnail Slider */}
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={3}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="swiper-product-thumbs-slider"
                  >
                    {activeBrandStyle?.brandStyleContentDTOList?.length > 0 ? activeBrandStyle?.brandStyleContentDTOList?.map(item => {
                      return (
                        <SwiperSlide key={item.id}>
                          {item.baseAddress ? (
                            <img src={item.baseAddress} alt={activeBrandStyle?.name} className="rounded-3 border-blue-lighter" style={{ height: '100px', objectFit: 'contain' }} />
                          ) : (
                            <div style={{ height: "200px", background: "#000" }}>
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/assets/img/logo.png"
                                }
                                alt=""
                                className="w-100 opacity-50"
                                style={{
                                  width: "310px",
                                  height: "200px",
                                  objectFit: "scale-down",
                                }}
                              />
                            </div>
                          )} 
                        </SwiperSlide>
                      )
                    }) : <SwiperSlide>
                          <div style={{ height: "200px", background: "#000" }}>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/img/logo.png"
                              }
                              alt=""
                              className="w-100 opacity-50"
                              style={{
                                width: "310px",
                                height: "200px",
                                objectFit: "scale-down",
                              }}
                            />
                          </div>
                        </SwiperSlide>
                }
                  </Swiper>
                  {/* End Thumnnail Slider */}
                  
                  {activeBrandStyle?.topIcon && 
                    <span className="rounded-pill bg-orange text-white small position-absolute z-index-top top-0 start-0 px-3 py-1 m-sm-3 m-2">{activeBrandStyle?.topIconText}</span>
                  }
                </div>
              </div>

              {/* Product Content */}
              {activeBrandStyle && 
                <div className="col-lg-5 offset-lg-1 offset-0">
                  {/* ScoreHunt Game */}
                  {showGame && availableGame ? (
                    <h1 className="mb-5 text-white text-white-lg" data-title-background>
                      {activeBrandStyle.name && activeBrandStyle.name }
                      <NavLink
                        to={{
                          pathname: "/games/score-hunt",
                          state: { fromAboutPage: true },
                        }}
                        className="w-auto d-flex align-items-center pt-3"
                      >
                        <img src={ScoreHuntBanner} alt="" className="bg-white p-1 rounded-circle shadow-lg" style={{ width: '48px', height: '48px', cursor: 'pointer' }} />
                        <h4 className="m-0 text-white ms-2">Puan Avı</h4>
                      </NavLink>
                    </h1>
                  ) : (
                    <h1 className="mb-5 text-white text-white-lg" data-title-background>
                     {activeBrandStyle.name && activeBrandStyle.name }
                    </h1>
                  )}
                  {/* End ScoreHunt Game */}


                  <div className="pt-lg-5 pt-0">
                    <div className="mb-5">
                      {activeBrandStyle.stock && 
                        <span className="text-blue small mb-2 d-block">{activeBrandStyle.stock} Stok</span>
                      }

                      {activeBrandStyle.price && 
                        <span className="h2 text-green mb-4 d-block fw-bold">{activeBrandStyle.price} ₺</span>
                      }
                                            
                      <div className="h4 text-gray mb-2 d-block fw-bold">
                        {activeBrandStyle.rate >= 1 ? (
                          <FontAwesomeIcon
                            icon={faStar}
                            className="text-yellow"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faStar} />
                        )}

                        {activeBrandStyle.rate >= 2 ? (
                          <FontAwesomeIcon
                            icon={faStar}
                            className="text-yellow"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faStar} />
                        )}

                        {activeBrandStyle.rate >= 3 ? (
                          <FontAwesomeIcon
                            icon={faStar}
                            className="text-yellow"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faStar} />
                        )}

                        {activeBrandStyle.rate >= 4 ? (
                          <FontAwesomeIcon
                            icon={faStar}
                            className="text-yellow"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faStar} />
                        )}

                        {activeBrandStyle.rate === 5 ? (
                          <FontAwesomeIcon
                            icon={faStar}
                            className="text-yellow"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faStar} />
                        )}
                      </div>
                      <span className="text-gray small mb-2 d-block">{activeBrandStyle.countOfRate} Değerlendirme</span>

                      <div>
                        {activeBrandStyle.favouriteStatus ? (
                          <a
                            href="#"
                            className="text-gray"
                            onClick={(e) =>
                              handleRemoveFavourite(e, activeBrandStyle.id)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faHeart}
                              style={{ fill: "red", color: 'red' }}
                            />
                            <span className="d-inline-block" style={{ marginLeft: '5px' }}>Vazgeç</span>
                          </a>
                        ) : (
                          <a
                            href="#"
                            className="text-gray text-decoration-none"
                            onClick={(e) => handleAddFavourite(e, activeBrandStyle.id)}
                          >
                            <FontAwesomeIcon 
                              icon={faHeart}
                            />
                            <span className="d-inline-block" style={{ marginLeft: '5px' }}>Beğen</span>
                          </a>
                        )}
                      </div>
                    </div>

                    {activeBrandStyle.productCode && 
                      <div className="row row-cols-2 g-0 align-items-center small">
                        <div className="col py-3 border-bottom">Ürün Kodu</div>
                        <div className="col py-3 border-bottom">{activeBrandStyle.productCode}</div>
                      </div>
                    }

                    {activeBrandStyle.brandStylePropertyDTOList?.length > 0 && activeBrandStyle.brandStylePropertyDTOList.map(item => (
                      <div className="row row-cols-2 g-0 align-items-center small" key={item.id}>
                        <div className="col py-3 border-bottom">{item.propertyName}</div>
                        <div className="col py-3 border-bottom">{item.propertyValue}</div>
                      </div>
                    ))}

                    {activeBrandStyle.description && 
                      <p className="mt-5 fw-light">{activeBrandStyle.description}</p>
                    }
                  </div>
                </div>
              }
              {/* End Product Content */}

            </div>
          </div>
        </section>

        {/* Other Brands */}
        <section className="mb-sm-10 mb-5">
          <div className="container">
            <h2 className="text-center mb-sm-8 mb-5">Diğer Ürünler</h2>
            <div className="row g-sm-4 g-2">
              {brandStyleList && brandStyleList.totalElements > 0
                ? brandStyleList.content
                    .filter((item) => item.id !== brandStyleId)
                    .slice(0, 4)
                    .map((item) => {
                      return (
                        <div className="col-xl-3 col-6" key={item.id}>
                          <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column">
                            {item.brandStyleContentDTOList?.length > 0 && item.name ? (
                              <img
                                src={item.brandStyleContentDTOList[0]?.baseAddress}
                                alt={item.name}
                                className="img-contain w-100 p-3" 
                                style={{width: '300px', height: '200px'}}
                              />
                            ) : (
                              <div style={{ background: "#000", width: '100%' }}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/assets/img/logo.png"
                                  }
                                  alt=""
                                  className="w-100 opacity-50"
                                  style={{
                                    width: "300px",
                                    height: "200px",
                                    objectFit: "scale-down",
                                  }}
                                />
                              </div>
                            )}

                            <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                              {item.name && (
                                <h5 className="fw-normal lh-base mb-2">
                                  {item.name}
                                </h5>
                              )}

                              <span
                                className="d-block small text-black mb-4"
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                              <NavLink
                                className="btn btn-green text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto"
                                to={{
                                  pathname: `/brand-product-detail/${item.brandSubCategoryId}/${item.id}`,
                                  brandStyleId: item.id,
                                }}
                              >
                                İncele
                                <i className="fas fa-arrow-right ms-3 d-sm-inline-block d-none" />
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      );
                    })
                : "Ürün Bulunmuyor!"}
            </div>
          </div>
        </section>
        {/* End Other Brands */}
      </main>

      <Footer />
    </>
  );
};

export default BrandProductDetail;
