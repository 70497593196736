/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { authAction } from "../../../redux/modules";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// Toastify
import showErrMessage from "../../../components/ShowErrMessage";
import showMessage from "../../../components/ShowMessage";

// Timer
import Timer from "../../../components/Timer";

// Styles
import "./styles.scss";

// Images
import Logo from "../../../assets/images/logo.png";

// Form Validations
import { Formik } from "formik";
import * as yup from "yup";

const PasswordReset = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // useStates
  const [formUsername, setFormUsername] = useState(null);
  const [sentCode, setSentCode] = useState(false);
  const [confirmationError, setConfirmationError] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [captchaText, setCaptchaText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingReSend, setIsLoadingReSend] = useState(false);

  // Timer
  const [expiryTime, setExpiryTime] = useState(null);
  const [timerExpired, setTimerExpired] = useState(false);

  const handleExpire = (expired) => {
    setTimerExpired(expired);
  };

  useEffect(() => {
    console.log("timerExpired: ", timerExpired);

    if (timerExpired === true) {
      showErrMessage(
        "Süreniz doldu! Lütfen, Tekrar Kod Gönder butonuna basarak kod isteyiniz!"
      );
    }

    return () => {};
  }, [timerExpired]);

  // Formik Schema
  const schema = yup.object({
    username: yup.string().required("Lütfen Üye Adı Giriniz!"),
    captcha: yup
      .string()
      .required("Lütfen Captcha Giriniz!")
      .test("is-captcha-correct", "Captcha yanlış!", function (value) {
        console.log("value: ", value);
        console.log("captchaText: ", captchaText);
        return value === captchaText;
      }),
  });

  const schemaConfirmationForm = yup.object({
    confirmCode: yup.string().required("Lütfen Onay Kodunu Giriniz!"),
  });

  // Get Captcha
  const handleGetCaptcha = (e) => {
    if (e) {
      e.preventDefault();
    }

    dispatch(authAction.getCaptcha()).then((res) => {
      if (res.isHttpSuccess) {
        setCaptcha(res?.base64);
        setCaptchaText(res?.text);
      }
    });
  };

  // Forgot Password
  const handleForgotPassword = (values) => {
    setIsLoading(true);

    if (values.username) {
      dispatch(authAction.forgotPassword(values.username, "FORGOTPASSWORD"))
        .then((res) => {
          if (res.isHttpSuccess) {
            // Loader Disable
            setIsLoading(false);

            // Stated for checks
            setFormUsername(values.username);

            // Code sent
            setSentCode(true);

            // Start the timer
            const time = new Date();
            time.setSeconds(time.getSeconds() + 90);
            setExpiryTime(time);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          setIsLoading(false);
        });
    }
  };

  // Forgot Password Confirm
  const handleForgotPasswordConfirm = (values) => {
    setIsLoading(true);

    if (values.confirmCode && formUsername) {
      dispatch(
        authAction.forgotPasswordConfirm(values.confirmCode, formUsername)
      )
        .then((res) => {
          if (res.isHttpSuccess) {
            setConfirmationError(false);
            setIsLoading(false);
            showMessage(
              "Şifreniz başarıyla değiştirildi ve telefon numaranıza SMS olarak gönderildi!"
            );
            history.push("/login");
          } else {
            setIsLoading(false);
            setConfirmationError(true);
          }
        })
        .catch((err) => {
          if (err.isHttpError) {
            setConfirmationError(true);
            setIsLoading(false);
          }
        });
    }
  };

  // Re-Send Confirm Mail - Forgot Password
  const handleReSendForgotPassword = (e) => {
    e.preventDefault();

    // Loader Active
    setIsLoadingReSend(true);

    if (formUsername) {
      dispatch(authAction.forgotPassword(formUsername, "FORGOTPASSWORD"))
        .then((res) => {
          if (res.isHttpSuccess) {
            // Loader Disable
            setIsLoadingReSend(false);

            // Start the timer
            const time = new Date();
            time.setSeconds(time.getSeconds() + 90);
            setExpiryTime(time);
            setTimerExpired(false);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    // Get Captcha
    handleGetCaptcha();
  }, []);

  return (
    <div className="public-container">
      <section className="bg-gray-lightest min-vh-100 forgot-password">
        <div className="container">
          <div className="row justify-content-center py-md-8 py-5">
            <div className="col-xl-4 col-lg-6 col-md-8 col-10">
              <div className="flex justify-content-center mb-4">
                <NavLink to="/login" className="d-inline-block">
                  <img src={Logo} alt="Jarvis Connection" className="mx-auto auth-logo" />
                </NavLink>
              </div>

              <h5 className="lh-base text-center fw-normal mb-3">
                Lütfen kullanıcı adınızı giriniz.
              </h5>
              <p className="mb-5 text-center fw-light">
                Yeni şifreniz girmiş olduğunuz kullanıcı adına kayıtlı telefon
                numaranıza gönderilecektir.
              </p>

              <Formik
                validationSchema={schema}
                onSubmit={(values) => handleForgotPassword(values)}
                initialValues={{
                  username: "",
                  captcha: "",
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form
                    className={sentCode === false ? "d-block" : "d-none"}
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <div className="form-body">
                      <div className="form-el form-el-container">
                        <div className="form-floating shadow-lg mb-2 rounded-3">
                          <input
                            className="form-control border-0 rounded-3 px-4"
                            type="text"
                            placeholder="Üye Adı"
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                          />
                          <label htmlFor="inputUsername" className="px-4">
                            Kullanıcı Adı
                          </label>
                        </div>
                        {errors.username && touched.username && (
                          <span className="errorMessage">
                            {errors.username}
                          </span>
                        )}

                        <div className="wrapper-captcha">
                          {captcha && (
                            <img
                              src={`data:image/png;base64,${captcha}`}
                              alt="Captcha"
                            />
                          )}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="reload-captcha"
                            onClick={(e) => handleGetCaptcha(e)}
                          >
                            {/*!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                            <path d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z" />
                          </svg>
                        </div>

                        <div className="form-floating shadow-lg mb-2 rounded-3">
                          <input
                            className="form-control border-0 rounded-3 px-4"
                            type="text"
                            placeholder="Captcha"
                            name="captcha"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.captcha}
                          />
                          <label htmlFor="inputCaptcha" className="px-4">
                            Captcha
                          </label>
                        </div>
                      </div>
                      {errors.captcha && touched.captcha && (
                        <span className="errorMessage">{errors.captcha}</span>
                      )}

                      <div className="form-btn">
                        <button
                          type="submit"
                          disabled={isLoading}
                          className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 rounded-3 w-100 mb-4"
                        >
                          {isLoading ? (
                            <span className="spinner-border spinner-border-sm me-3"></span>
                          ) : null}
                          Gönder <i className="fas fa-arrow-right ms-3"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>

              <Formik
                validationSchema={schemaConfirmationForm}
                onSubmit={(values) => handleForgotPasswordConfirm(values)}
                initialValues={{
                  confirmCode: "",
                  username: formUsername,
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form
                    className={sentCode === true ? "d-block" : "d-none"}
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <div className="form-body">
                      <div className="form-el form-el-container">
                        <div className="mb-5">
                          {expiryTime && (
                            <Timer
                              expiryTimestamp={expiryTime}
                              onExpire={handleExpire}
                            />
                          )}
                        </div>

                        <div className="form-floating shadow-lg mb-2 rounded-3">
                          <input
                            className="form-control border-0 rounded-3 px-4"
                            type="text"
                            placeholder="Onay Kodu"
                            name="confirmCode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmCode}
                            disabled={timerExpired ? true : false}
                          />
                          <label htmlFor="inputCaptcha" className="px-4">
                            Onay Kodu
                          </label>
                        </div>
                        {errors.confirmCode && touched.confirmCode && (
                          <span className="errorMessage">
                            {errors.confirmCode}
                          </span>
                        )}

                        {confirmationError && (
                          <span className="errorMessage">
                            Girdiğiniz onay kodu eşleşmedi. Lütfen tekrar
                            deneyiniz!
                          </span>
                        )}
                      </div>

                      <div className="form-btn">
                        <button
                          type="submit"
                          disabled={timerExpired ? true : false}
                          className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 rounded-3 w-100 mb-4"
                        >
                          {isLoading === true ? (
                            <span className="spinner-border spinner-border-sm me-3"></span>
                          ) : null}
                          Gönder <i className="fas fa-arrow-right ms-3"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>

              <div className={sentCode === true ? "d-block" : "d-none"}>
                <button
                  type="button"
                  onClick={(e) => handleReSendForgotPassword(e)}
                  className="btn btn-warning text-white py-sm-3 py-2 px-sm-4 px-3 rounded-3 w-100 mb-4"
                >
                  {isLoadingReSend === true ? (
                    <span className="spinner-border spinner-border-sm me-3"></span>
                  ) : null}
                  Tekrar Kod Gönder <i className="fas fa-arrow-right ms-3"></i>
                </button>
              </div>
              <div className="text-center text-gray small">
                <Link to="/login">Giriş Yap</Link> veya{" "}
                <Link to="/signUp">Üye Ol</Link>
              </div>
              <div className="text-center mt-md-8 mt-5">
                <p>
                  <a
                    href="tel:08505325787"
                    className="text-gray-darkest text-decoration-none"
                  >
                    <i className="fas fa-phone-alt me-3"></i>0850 532 5787
                  </a>
                </p>
                <p className="fw-lighter opacity-50">
                  Copyright © 2024 Jarvis. Tüm Hakları Saklıdır.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PasswordReset;
