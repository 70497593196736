export const GET_ABOUT_US_PENDING = 'GET_ABOUT_US_PENDING';
export const GET_ABOUT_US_FULFILLED = 'GET_ABOUT_US_FULFILLED';
export const GET_ABOUT_US_REJECTED = 'GET_ABOUT_US_REJECTED';

export const GET_ABOUT_US_BY_TYPE_PENDING = 'GET_ABOUT_US_BY_TYPE_PENDING';
export const GET_ABOUT_US_BY_TYPE_FULFILLED = 'GET_ABOUT_US_BY_TYPE_FULFILLED';
export const GET_ABOUT_US_BY_TYPE_REJECTED = 'GET_ABOUT_US_BY_TYPE_REJECTED';

export const GET_FOOTER_ABOUT_US_PENDING = 'GET_FOOTER_ABOUT_US_PENDING';
export const GET_FOOTER_ABOUT_US_FULFILLED = 'GET_FOOTER_ABOUT_US_FULFILLED';
export const GET_FOOTER_ABOUT_US_REJECTED = 'GET_FOOTER_ABOUT_US_REJECTED';

export const GET_FOOTER_ABOUT_US_LIST_BY_TYPE_PENDING = 'GET_FOOTER_ABOUT_US_LIST_BY_TYPE_PENDING';
export const GET_FOOTER_ABOUT_US_LIST_BY_TYPE_FULFILLED = 'GET_FOOTER_ABOUT_US_LIST_BY_TYPE_FULFILLED';
export const GET_FOOTER_ABOUT_US_LIST_BY_TYPE_REJECTED = 'GET_FOOTER_ABOUT_US_LIST_BY_TYPE_REJECTED';