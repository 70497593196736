import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userAction, shopAction, searchAction } from '../../redux/modules';

// Header - Footer
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import './styles.scss';

const Search = () => {
  const dispatch = useDispatch();
  const searchData = useSelector(store => store.search.searchData);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const reloadControl = localStorage.getItem('reControl');

    if (!reloadControl) {
      localStorage.setItem('reControl', 'true');
      dispatch(userAction.setLoginedUser('logined'));
    }

    // Get Cart
    dispatch(shopAction.getCart());

    // Search Request
    const text = localStorage.getItem('search');
    setSearchText(text);
    if (text && text.length > 1) {
      dispatch(searchAction.getSearch(text));
    }

    return () => {};
  }, [dispatch]);

  useEffect( ()=> {

    if( searchData && searchData.length > 0 ) {
      console.log("searchData: ", searchData);
      for( let item of searchData) {
        console.log( "item: ", item );
     }
    }

  }, [searchData]);

  useEffect( ()=> {
    console.log("searchText: ", searchText);
  }, [searchText]);

  return (
  <>
    <Header></Header>
    <div className="search-page">
      <Container>
        <Row className="justify-content-md-center mt-5">
          <Col xs={12} md={10}>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <div className="global-grid-component product-component">
                  <Row className={"mb-3"}>
                    <Col xs={12}>
                      <h3 className="search-page-title text-center">"{searchText && searchText.length > 1 && searchText}" arama sonuçlarında bulundu...</h3>
                    </Col>
                  </Row>
                  <Row>
                    {searchData && searchData.length > 0
                      ? searchData.map((item, index) => {
                        return (
                          <Col key={index} xs={12} sm={6} md={4} lg={4}>
                            <div className="global-grid-item">
                              <Link
                                to={{
                                  pathname: `${item.resultPageURL}`,
                                }}
                              >
                                {item.icon ? (
                                  <div className="global-grid-item-image-wrapper">
                                    <img
                                      src={item.icon && item.icon}
                                      alt={item.name}
                                      className="global-grid-item-image img-fluid"
                                    />
                                  </div>
                                ) : (
                                  <div className="global-grid-none-image"></div>
                                )}
                                <div className="global-grid-item-content">
                                  <h3 className="global-grid-item-name">{item.name && item.name}</h3>
                                  <p className="global-grid-item-description">{item.description && item.description}</p>
                                </div>
                              </Link>

                            </div>
                          </Col>
                        );
                      })
                      : null}
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
    <Footer></Footer>
  </>
  );
};

export default Search;
