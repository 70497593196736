import * as productType from "./product.type";

const initialState = {
  productData: [],
  productRateData: [],
  productFavourite: null,
  productFavouriteList: null,
  error: null,
  isLoading: false
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${productType.GET_PRODUCT_BY_ID_PENDING}`:
    case `${productType.GET_PRODUCT_RATE_BY_ID_PENDING}`:
    case `${productType.GET_PRODUCT_FAVOURITES_PENDING}`:
    case `${productType.SET_FAVOURITE_PRODUCT_BY_ID_PENDING}`:
    case `${productType.REMOVE_FAVOURITE_PRODUCT_BY_ID_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${productType.GET_PRODUCT_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        productData: payload.data
      };
    case `${productType.GET_PRODUCT_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        productData: [],
        error: payload
      };

    case `${productType.GET_PRODUCT_RATE_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        productRateData: payload.data
      };
    case `${productType.GET_PRODUCT_RATE_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        productRateData: [],
        error: payload
      };

    case `${productType.GET_PRODUCT_FAVOURITES_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        productFavouriteList: payload.data
      };
    case `${productType.GET_PRODUCT_FAVOURITES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        productFavouriteList: null,
        error: payload
      };

    case `${productType.SET_FAVOURITE_PRODUCT_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        productFavourite: payload.data
      };
    case `${productType.SET_FAVOURITE_PRODUCT_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        productFavourite: null,
        error: payload
      };

    case `${productType.REMOVE_FAVOURITE_PRODUCT_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        productFavourite: payload.data
      };
    case `${productType.REMOVE_FAVOURITE_PRODUCT_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        productFavourite: null,
        error: payload
      };

    default:
      return state;
  }
};

export default reducer;
