import * as academyTypes from './magazine.type';

import { API } from '../../../services/http.service';

const REQUEST_URL = '';

const config = {
  headers: { 'Content-Type': 'application/json-application' },
};

// Magazine List
export const getArticleList = (articleType, size = 9999, sort='updateDate,desc') => async dispatch => {
  if (articleType === "TV") {
    dispatch({ type: academyTypes.GET_TV_LIST_PENDING });
  } else {
    dispatch({ type: academyTypes.GET_MAGAZINE_LIST_PENDING });
  }

  try {
    const response = await API.get(`${REQUEST_URL}/magazine/article/articleType/${articleType}?size=${size}&sort=${sort}`, config);
    if (articleType === "TV") {
      dispatch({
        type: academyTypes.GET_TV_LIST_FULFILLED,
        payload: response,
      });
    } else {
      dispatch({
        type: academyTypes.GET_MAGAZINE_LIST_FULFILLED,
        payload: response,
      });
    }

    return Promise.resolve(response);
  } catch (error) {
    if (articleType === "TV") {
      dispatch({
        type: academyTypes.GET_TV_LIST_REJECTED,
        payload: error,
      });
    } else {
      dispatch({
        type: academyTypes.GET_MAGAZINE_LIST_REJECTED,
        payload: error,
      });
    }

    return Promise.reject(error);
  }
};

// Magazine Category List
export const getArticleCategoryList = pageType => async dispatch => {
  dispatch({ type: academyTypes.GET_MAGAZINE_CATEGORY_LIST_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/magazine/topicCategoryList/${pageType}`, config);
    dispatch({
      type: academyTypes.GET_MAGAZINE_CATEGORY_LIST_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: academyTypes.GET_MAGAZINE_CATEGORY_LIST_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Magazine Category By Id
export const getMagazineCategoryById = (articleType, id) => async dispatch => {
  if (articleType === 'TV') {
    dispatch({ type: academyTypes.GET_TV_CATEGORY_BY_ID_PENDING });
  } else {
    dispatch({ type: academyTypes.GET_MAGAZINE_CATEGORY_BY_ID_PENDING });
  }

  try {
    const response = await API.get(`${REQUEST_URL}/magazine/article/category/${id}`, config);

    if (articleType === "TV") {
      dispatch({
        type: academyTypes.GET_TV_CATEGORY_BY_ID_FULFILLED,
        payload: response,
      });
    } else {
      dispatch({
        type: academyTypes.GET_MAGAZINE_CATEGORY_BY_ID_FULFILLED,
        payload: response,
      });
    }
    return Promise.resolve(response);
  } catch (error) {
    if (articleType === "TV") {
      dispatch({
        type: academyTypes.GET_TV_CATEGORY_BY_ID_REJECTED,
        payload: error,
      });
    } else {
      dispatch({
        type: academyTypes.GET_MAGAZINE_CATEGORY_BY_ID_REJECTED,
        payload: error,
      });
    }
    
    return Promise.reject(error);
  }
};

// Magazine Sub Category By Id
export const getMagazineSubCategoryById = (articleType, id) => async dispatch => {
  if (articleType === 'TV') {
    dispatch({ type: academyTypes.GET_TV_SUB_CATEGORY_BY_ID_PENDING });
  } else {
    dispatch({ type: academyTypes.GET_MAGAZINE_SUB_CATEGORY_BY_ID_PENDING });
  }
  
  try {
    const response = await API.get(`${REQUEST_URL}/magazine/article/subCategory/${id}`, config);

    if (articleType === "TV") {
      dispatch({
        type: academyTypes.GET_TV_SUB_CATEGORY_BY_ID_FULFILLED,
        payload: response,
      });
    } else {
      dispatch({
        type: academyTypes.GET_MAGAZINE_SUB_CATEGORY_BY_ID_FULFILLED,
        payload: response,
      });
    }

    return Promise.resolve(response);
  } catch (error) {
    if (articleType === "TV") {
      dispatch({
        type: academyTypes.GET_TV_SUB_CATEGORY_BY_ID_REJECTED,
        payload: error,
      });
    } else {
      dispatch({
        type: academyTypes.GET_MAGAZINE_SUB_CATEGORY_BY_ID_REJECTED,
        payload: error,
      });
    }

    return Promise.reject(error);
  }
};