/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  homeAction,
  userAction,
  gamesAction,
} from "../../redux/modules";

// Header Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import TitleBackground from "../../components/TitleBackground";

// Styles
import "./styles.scss";

const BrandDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Store
  const brandList = useSelector((store) => store.home.brandList);

  // States
  const [brandId, setBrandId] = useState(null);
  const [showGame, setShowGame] = useState(false);
  const [availableGame, setAvailableGame] = useState(false);

  // Game Score Hunt
  const scoreHunt = useSelector((store) => store.games.scoreHunt);

  // Game Score Hunt Control
  // TODO: Bu kontrol neden buraya eklenmiş? Oyunu test ederken kontrol edilecek!
  //const scoreHuntControl = useSelector((store) => store.games.scoreHuntControl);

  useEffect(() => {
    const reloadControl = localStorage.getItem("reControl");

    if (!reloadControl) {
      localStorage.setItem("reControl", "true");
      dispatch(userAction.setLoginedUser("logined"));
    }

    // Score Hunt
    dispatch(gamesAction.scoreHunt());

    // Score Hunt Control
    dispatch(gamesAction.scoreHuntControl()).then((res) => {
      if (res.isHttpSuccess) {
        if (res.data.remainTime > 0) {
          setAvailableGame(true);
        }
      }
    });

    // Get BrandList
    dispatch(homeAction.getBrandList());
    return () => { };
  }, [dispatch]);

  useEffect(() => {
    if (location?.pathname) {
      setBrandId(
        parseInt(
          location.pathname.split("/")[location.pathname.split("/").length - 1]
        )
      );
    }

    return () => { };
  }, [location]);

  useEffect(() => {
    if (scoreHunt?.brandId === brandId) {
      setShowGame(true);
    }
  }, [scoreHunt, brandId]);

  return (
    <>
      <Header />

      <main>
        <section className="position-relative pt-sm-5 pt-3 mb-sm-10 mb-5" data-title-background="pt">
          <TitleBackground className="d-lg-block d-none" />
          <div className="container">
            <div className="d-lg-flex d-none text-white small mb-4 opacity-50" data-title-background>
              <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
              <span className="me-3">»</span>
              <NavLink to="/brands" className="text-white text-decoration-none me-3">Markalarımız</NavLink>
              <span className="me-3">»</span>

              <NavLink to={`/brands/${brandId}`} className="text-white text-decoration-none me-3">{brandList?.content.find((item) => { return item.id == brandId })?.name}</NavLink>
            </div>

            {brandList?.content
              ?.filter((item) => item.id === brandId)
              .map((brandItem) => (
                <div className="row gy-5" key={brandItem.id}>
                  <div className="col-lg-5">
                    <div className="position-relative rounded-3 overflow-hidden mb-2">
                      <div className="swiper sliderProduct bg-white border-blue-lighter">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <a href={brandItem.icon} className="d-block" data-fancybox>
                              <img src={brandItem.icon} alt="" className="w-100 img-contain" style={{ width: '500px', height: '600px', padding: '30px' }} />
                            </a>
                          </div>
                          {brandItem.listImages ? (
                            brandItem.listImages.map((item, i) => (
                              <div className="swiper-slide" key={i}>
                                <a href={item} className="d-block" data-fancybox>
                                  <img src={item} alt="" className="w-100 img-contain" style={{ width: '500px', height: '600px' }} />
                                </a>
                              </div>
                            ))
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="swiper sliderProductThumbnails">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <img src={brandItem.icon} alt="" className="w-100 p-2 img-contain rounded-3 border-blue-lighter" style={{ width: '84px', height: '84px' }} />
                        </div>
                        {brandItem.listImages ? (
                          brandItem.listImages.map((item, i) => (
                            <div className="swiper-slide" key={i}>
                              <img src={item} alt="" className="w-100 p-2 img-contain rounded-3 border-blue-lighter" style={{ width: '84px', height: '84px' }} />
                            </div>
                          ))
                        ) : null}
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-lg-5 offset-lg-1 offset-0">
                    {/* ScoreHunt Game */}
                    {showGame && availableGame ? (
                      <h1 className="mb-5 text-white text-white-lg" data-title-background>
                        {brandItem.name}
                        <NavLink
                          to={{
                            pathname: "/games/score-hunt",
                            state: { fromAboutPage: true },
                          }}
                          className="w-auto d-flex align-items-center pt-3"
                        >
                          <img src="../assets/img/game01.png" alt="" className="bg-white p-1 rounded-circle shadow-lg" style={{ width: '48px', height: '48px', cursor: 'pointer' }} />
                          <h4 className="m-0 text-white ms-2">Puan Avı</h4>
                        </NavLink>
                      </h1>
                    ) : (
                      <h1 className="mb-5 text-white text-white-lg" data-title-background>
                        {brandItem.name}
                      </h1>
                    )}
                    {/* End ScoreHunt Game */}

                    <div className="pt-lg-5 pt-0">
                      {brandItem.description ? (
                        <p className="lead mb-5" dangerouslySetInnerHTML={{ __html: brandItem.description }} />
                      ) : null}

                      {brandItem.website ? (
                        <a href={brandItem.website} className="lead mb-5" target="_blank" rel="noreferrer">{brandItem.website}</a>
                      ) : null}

                      <div className="row row-cols-2 g-0 align-items-center small">
                        {brandItem.listProperty ? (
                          brandItem.listProperty.map((item, i) => (
                            <React.Fragment key={i}>
                              <div className="col py-3 border-bottom">{item.propertyName}</div>
                              <div className="col py-3 border-bottom">{item.propertyValue}</div>
                            </React.Fragment>
                          ))
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </section>

        {/* Other Brands */}
        {brandList?.totalElements > 1 &&
          <section className="mb-sm-10 mb-5">
            <div className="container">
              <h2 className="text-center mb-sm-8 mb-5">Diğer Markalar</h2>
              <div className="row g-sm-4 g-2">
                {brandList && brandList.totalElements > 0
                  ? brandList.content
                    .filter((item) => item.id !== brandId)
                    .slice(0, 4)
                    .map((item) => {
                      return (
                        <div className="col-xl-3 col-6" key={item.id}>
                          <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column">
                            {item.icon && item.name ? (
                              <img
                                src={item.icon}
                                alt={item.name}
                                className="img-contain w-100 p-3"
                                style={{ width: '300px', height: '200px' }}
                              />
                            ) : (
                              <div className="product-brand-none-image" style={{ width: '300px', height: '200px' }}></div>
                            )}

                            <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                              {item.name && (
                                <h5 className="fw-normal lh-base mb-2">
                                  {item.name}
                                </h5>
                              )}

                              <span
                                className="d-block small text-black mb-4"
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                              <NavLink
                                className="btn btn-green text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto"
                                to={{
                                  pathname: "/brand-detail/" + item.id,
                                  brandId: item.id,
                                }}
                              >
                                İncele
                                <i className="fas fa-arrow-right ms-3 d-sm-inline-block d-none" />
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : "Marka Bulunmuyor!"}
              </div>
            </div>
          </section>
        }
        {/* End Other Brands */}
      </main>

      <Footer />
    </>
  );
};

export default BrandDetail;