import * as brandTypes from "./brand.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

// Brand List
export const getBrandList = () => async dispatch => {
  dispatch({ type: brandTypes.GET_BRAND_LIST_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/brand/brandList`, config);
    dispatch({
      type: brandTypes.GET_BRAND_LIST_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: brandTypes.GET_BRAND_LIST_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Brand List
export const getBrandProductFavouriteList = () => async dispatch => {
  dispatch({ type: brandTypes.GET_BRAND_PRODUCT_FAVOURITE_LIST_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/brand/favourite/brandStyleList`, config);
    dispatch({
      type: brandTypes.GET_BRAND_PRODUCT_FAVOURITE_LIST_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: brandTypes.GET_BRAND_PRODUCT_FAVOURITE_LIST_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Brand Magazine - for details page
export const getBrandMagazine = (brandTopicId) => async dispatch => {
  dispatch({ type: brandTypes.GET_BRAND_MAGAZINE_PENDING });

  try {
    let response;

    if (brandTopicId) {
      response = await API.get(`${REQUEST_URL}/brand/brandMagazine/${brandTopicId}`, config);
    }

    dispatch({
      type: brandTypes.GET_BRAND_MAGAZINE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: brandTypes.GET_BRAND_MAGAZINE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Brand Topic - for details page
export const getBrandTopic = (brandId) => async dispatch => {
  dispatch({ type: brandTypes.GET_BRAND_TOPIC_PENDING });

  try {
    let response;

    if (brandId) {
      response = await API.get(`${REQUEST_URL}/brand/brandTopic/${brandId}`, config);
    }

    dispatch({
      type: brandTypes.GET_BRAND_TOPIC_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: brandTypes.GET_BRAND_TOPIC_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Brand Style List - BrandId or All Brands
export const getBrandStyleList = (page=0, size=2000, sort='name,asc', brandId) => async dispatch => {
  dispatch({ type: brandTypes.GET_BRAND_STYLE_LIST_PENDING });

  try {
    let response;

    if (brandId) {
      response = await API.get(
        `${REQUEST_URL}/brand/brandStyleList/${brandId}?page=${page}&size=${size}&sort=${sort}`,
        config
      );
    } else {
      response = await API.get(`${REQUEST_URL}/brand/get/brandStyleList?page=${page}&size=${size}&sort=${sort}`, config);
    }
    dispatch({
      type: brandTypes.GET_BRAND_STYLE_LIST_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: brandTypes.GET_BRAND_STYLE_LIST_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Brand SubCategory Style List - (Product List By SubCategoryId)
export const getBrandSubCategoryStyleList = (subCategoryId, page=0, size=2000, sort='name,asc') => async dispatch => {
  dispatch({ type: brandTypes.GET_BRAND_SUBCATEGORY_STYLE_LIST_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/brand/subCategory/brandStyleList/${subCategoryId}?page=${page}&size=${size}&sort=${sort}`,
      config
    );

    dispatch({
      type: brandTypes.GET_BRAND_SUBCATEGORY_STYLE_LIST_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: brandTypes.GET_BRAND_SUBCATEGORY_STYLE_LIST_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Brand CategoryList
export const getBrandCategoryList = categoryId => async dispatch => {
  dispatch({ type: brandTypes.GET_BRAND_CATEGORY_LIST_PENDING });

  try {
    let response;
    if (categoryId) {
      response = await API.get(
        `${REQUEST_URL}/brand/brandCategoryList/${categoryId}`,
        config
      );
    } else {
      response = await API.get(
        `${REQUEST_URL}/brand/brandCategoryList`,
        config
      );
    }
    dispatch({
      type: brandTypes.GET_BRAND_CATEGORY_LIST_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: brandTypes.GET_BRAND_CATEGORY_LIST_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Brand CategoryList
export const setFavouriteBrandStyle = brandStyleId => async dispatch => {
  dispatch({ type: brandTypes.SET_FAVOURITE_BRAND_STYLE_PENDING });

  try {
    const data = {
      brandStyleId: brandStyleId,
    }

    const response = await API.post(`${REQUEST_URL}/brand/favouriteBrandStyle/save`, data, config);

    dispatch({
      type: brandTypes.SET_FAVOURITE_BRAND_STYLE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: brandTypes.SET_FAVOURITE_BRAND_STYLE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Brand CategoryList
export const deleteFavouriteBrandStyle = brandStyleId => async dispatch => {
  dispatch({ type: brandTypes.DELETE_FAVOURITE_BRAND_STYLE_PENDING });

  try {
    const data = {
      brandStyleId: brandStyleId,
    }

    const response = await API.post(`${REQUEST_URL}/brand/favouriteBrandStyle/remove`, data, config);

    dispatch({
      type: brandTypes.DELETE_FAVOURITE_BRAND_STYLE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: brandTypes.DELETE_FAVOURITE_BRAND_STYLE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};