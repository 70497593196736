import React from 'react';
import style from './modal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ isOpen, onClose, children, showCloseButton = false, showCloseIconButton = true }) => {
  if (!isOpen) return null;

  return (
    <div className={style.modalOverlay}>
      <div className={style.modal}>
        {showCloseIconButton && <div className={style.closeIconButton} onClick={onClose}><FontAwesomeIcon icon={faTimes} /></div> }
        <div className={style.modalContent}>
          {children}
        </div>
        {showCloseButton && <button className={style.modalCloseButton} onClick={onClose}>Close</button>}
      </div>
    </div>
  );
};

export default Modal;
