export const GET_CART_PENDING = "GET_CART_PENDING";
export const GET_CART_FULFILLED = "GET_CART_FULFILLED";
export const GET_CART_REJECTED = "GET_CART_REJECTED";

export const ADD_TO_CART_PENDING = "ADD_TO_CART_PENDING";
export const ADD_TO_CART_FULFILLED = "ADD_TO_CART_FULFILLED";
export const ADD_TO_CART_REJECTED = "ADD_TO_CART_REJECTED";

export const REMOVE_CART_ITEM_BY_ID_PENDING = "REMOVE_CART_ITEM_BY_ID_PENDING";
export const REMOVE_CART_ITEM_BY_ID_FULFILLED =
  "REMOVE_CART_ITEM_BY_ID_FULFILLED";
export const REMOVE_CART_ITEM_BY_ID_REJECTED =
  "REMOVE_CART_ITEM_BY_ID_REJECTED";
  export const REMOVE_ALL_ITEMS = "REMOVE_ALL_ITEMS";
