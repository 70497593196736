/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction, magazineAction } from "../../redux/modules";

import { NavLink, useParams } from "react-router-dom";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

const MagazineDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [filteredMagazine, setFilteredMagazine] = useState(null);

  const magazineList = useSelector((store) => store.magazine.magazineList);

  useEffect(() => {
    const reloadControl = localStorage.getItem("reControl");

    if (!reloadControl) {
      localStorage.setItem("reControl", "true");
      dispatch(userAction.setLoginedUser("logined"));
    }
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    // dispatch(profileAction.getPageDescription("MAGAZINE"));
    dispatch(magazineAction.getArticleList("MAGAZINE"));
    dispatch(magazineAction.getArticleCategoryList("MAGAZINE"));

    // TV
    dispatch(magazineAction.getArticleList("TV", "5"));

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (magazineList?.content?.length > 0) {
      const magazine = magazineList.content.find(item => item.id === parseInt(id));
      if (magazine) {
        setFilteredMagazine(magazine);
      }
    }
  }, [magazineList, id]);

  useEffect(() => {
    if (filteredMagazine) {
      console.log("filteredMagazine:", filteredMagazine);
    }
  }, [filteredMagazine]);

  return (
    <div className="page-container">
      <Header />
      
      <main>
          <section className="bg-blue pt-5 pb-5">
              <div className="container"> 
                  <div className="d-lg-flex d-none text-white small mb-5 opacity-50" data-title-background>
                      <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
                      <span className="me-3">»</span>
                      <NavLink to="/magazine" className="text-white text-decoration-none me-3">Dergi</NavLink>
                      <span className="me-3">»</span>
                      {filteredMagazine?.topicCategoryName && 
                        <a href="javascript:void(0)" className="text-white text-decoration-none">
                          {filteredMagazine?.topicCategoryName && filteredMagazine.topicCategoryName}
                        </a>
                      }
                  </div>

                  {filteredMagazine ?
                    <div className="row justify-content-center mb-5" data-title-background>
                        <div className="col-xl-8 col-lg-10 text-center">
                            <h1 className="lh-base mb-4 text-white">{filteredMagazine.name}</h1>
                            <div className="opacity-50 small text-white">
                                <span>{filteredMagazine.dateCreated}</span>
                                <span className="ms-3">#{filteredMagazine.topicSubCategoryName}</span>
                            </div>
                        </div>
                    </div>
                  : (
                    <p>Magazine bulunamadı yada yükleniyor...</p>
                  )}
              </div>
          </section>

          <section className="position-relative mb-sm-10 mb-5 mt-n5">
              <div className="container">
                {filteredMagazine ? (
                  <div>
                    <div className="row justify-content-center mb-sm-8 mb-5">
                      <div className="col-xl-10">
                        <div className="overflow-hidden rounded-3">
                          {filteredMagazine?.articleContentDTOList?.length > 0 ? 
                            <img src={filteredMagazine?.articleContentDTOList[0].baseAddress} alt="" className="img-cover w-100" /> :
                            <div className="" style={{ background: '#ddd', height: '390px' }}></div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-xl-8 col-lg-10 content">
                        <p dangerouslySetInnerHTML={{__html: filteredMagazine.description}}></p>
                      </div>
                    </div>

                  </div>
                ) : (
                  <p>Magazine bulunamadı yada yükleniyor...</p>
                )}
              </div>
          </section>
      </main>

      <Footer />
    </div>
  );
};

export default MagazineDetail;
